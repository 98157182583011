import Ingredient from '../Ingredient/Ingredient';
import FooterLogo from '../FooterLogo/FooterLogo';

const ImgUrl = 'https://aiodefilesbucket.s3.amazonaws.com/ingimages/'; //{id}.jpg`;

export default function ScoreCalculatedTopIngredients(props) {
  //console.log("props ScoreCalculatedTopIngredients", props);

  return (
    <div
      className={
        props.isTopIngrediantsOn
          ?
          props.isOnStep > 2
            ? 'top-ingredients top-ingredients_calculated top-ingredients_two-rows-title'
            : 'top-ingredients top-ingredients_calculated'
          : 'top-ingredients_inactive'
      }
    >
      {/* <div className="top-ingredients-logo"></div> */}
      <div className="top-ingredients-content top-ingredients-content_calculated">
        <div className="top-ingredients-text score-calculated-top-ingredients-text">
          Your Top Ingredients:
        </div>
        <div className={props.recommendedIngredients.length === 2
          ? "top-ingredients-images-container top-ingredients-images-container_calculated top-ingredients-images-container_two-ingredients"
          : "top-ingredients-images-container top-ingredients-images-container_calculated"}>
          {
            /* TODO if i get Empty array to have offline image  */
            props.recommendedIngredients.map((ingredient) => (
              <Ingredient
                ingredient={ingredient}
                ImgUrl={ImgUrl}
                key={ingredient.id}
                id={ingredient.id}
                ingredientName={ingredient.name}
                src={`${ImgUrl + ingredient.id}.jpg`}
              />

              // <div
              //   className={'top-ingredients-ingredient'}
              //   id={ingredient.id}
              //   key={ingredient.id}
              // >
              //   <img
              //     className="top-ingredients-image"
              //     src={`${ImgUrl}${ingredient.id}.jpg`}
              //     // onLoad={loadController}
              //   />
              //   <p className="top-ingredients-name">{ingredient.name}</p>
              // </div>
            ))
          }
        </div>
        {window.innerWidth <= 768 && <FooterLogo class='' />}
      </div>
    </div>
  );
}
