import IngredientImage from "../IngredientImage/IngredientImage";

const ImgUrl = `https://aiodefilesbucket.s3.amazonaws.com/ingimages/{id}.jpg`

export default function IngredientsSmall(props) {//TODO: need to fix it
  // console.log("props",props);
  let ingredintSmallComponents = [];
  if (props.ingredientsProduct !== undefined) {
    ingredintSmallComponents = props.ingredientsProduct.map((ingredient) => (
      <div key={ingredient.id}>
        <div className="ingredients-small-content">
          <IngredientImage
            width={window.innerWidth > 430 ? '90px' : '70px'}
            height={window.innerWidth > 430 ? '90px' : '70px'}
            src={ImgUrl.replace('{id}', ingredient.id)}
            border={true}
            name={ingredient.name}
            isOverlay={true}
            overlaySmall={true} />
          {/* <img className="ingredients-small-img" src={ImgUrl.replace('{id}', ingredient.id)} alt="ingredient" /> */}
          <p className="ingredients-small-ingredient-name">{ingredient.name}</p>
        </div>
      </div>
    ));
  }


  return (
    <div className="ingredients-small">
      <h4 className="ingredients-small-title">Main ingredients: </h4>
      <div className="ingredients-small-img-container">
        {ingredintSmallComponents}
      </div>
    </div>
  );
}
