import { motion } from "framer-motion";
import IngredientImage from "../IngredientImage/IngredientImage";
import FooterLogo from "../FooterLogo/FooterLogo";

const ImgUrl = "https://aiodefilesbucket.s3.amazonaws.com/ingimages/"; //{id}.jpg`;

export default function TopIngredients(props) {
  return (
    <div
      className={
        props.isTopIngrediantsOn
          ? "top-ingredients"
          : "top-ingredients_inactive"
      }
    >
      {/* <div className="top-ingredients-logo"></div> */}
      <motion.div
        animate={{ opacity: [0, 100] }}
        transition={{ ease: "linear", duration: 0.5 }}
        className="top-ingredients-content"
      >
        <div className="top-ingredients-alignment">
          <div className="top-ingredients-text">FRÉ uses more than</div>
          <span className="top-ingredients-number">50</span>
        </div>
        <div className="top-ingredients-text">
          <span className="top-ingredients-text_highlighted">
            active ingredients
          </span>{" "}
          to combat UV damage and fight breakouts & the signs of aging
        </div>
        <motion.div
          animate={{ x: [0, -5500] }}
          transition={{ ease: "linear", duration: 120, repeat: Infinity }}
          className="top-ingredients-images-container"
        >
          {props.recommendedIngredients.map((ingredient) => (
            <IngredientImage
              width={window.innerWidth > 768 ? "90px" : "50px"}
              height={window.innerWidth > 768 ? "90px" : "50px"}
              src={`${ImgUrl + ingredient.ID}.jpg`}
              key={ingredient.ID}
              name={ingredient.name}
            />
            // <img
            //   className="top-ingredients-image"
            //   src={`${ImgUrl + ingredient.ID}.jpg`}
            //   key={ingredient.ID}
            //   alt={ingredient.name}
            // />
          ))}
        </motion.div>
        {window.innerWidth <= 768 && <FooterLogo class="" />}
      </motion.div>
      
    </div>
  );
}
