import SingleChoiceQuestion from '../SingleChoiceQuestion/SingleChoiceQuestion';
import useScreenSize from '../../utils/useScreenSize';
import InputQuestion from '../InputQuestion/InputQuestion';

export default function Step1(props) {
  const step = 'Step1';
  const [width, breakpoint] = useScreenSize();

  if (width >= breakpoint) {
    return (
      <>
        <InputQuestion
          questionTitle={'How should we call you?'}
          placeholder={'Name or nickname'}
          buttonText={'Next'}
          nextInnerStep={props.nextInnerStep}
          question={'Q1'}
          step={step}
          onInputChange={props.getUserName}
          isRequired={true}
          length={[2, 15]}
          getUserName={props.getUserName}
          isNameEmpty={props.isNameEmpty}
        />

        <SingleChoiceQuestion
          questionTitle={"How old are you?"}
          titleLines={props.titleLines}
          nextInnerStep={props.nextInnerStep}
          previousInnerStep={props.previousInnerStep}
          userScores={props.userScores}
          updateUserScores={props.updateUserScores}
          updateUserResponses={props.updateUserResponses}
          step={step}
          question={'Q2'}
          options={[
            {
              responseText: '20 or below',
            },
            {
              responseText: '21 - 30',
            },
            {
              responseText: '31 - 40',
              score: {
                Aging: 1
              }
            },
            {
              responseText: '41 - 50',
              score: {
                Aging: 2
              }
            },
            {
              responseText: '51 - 60',
              score: {
                Aging: 3
              }
            },
            {
              responseText: '61 and up',
              score: {
                Aging: 4
              }
            },
            {
              responseText: 'Prefer not to say',
              score: {
                Aging: 0
              }
            },
          ]}
          getRecommendedIngredients={props.getRecommendedIngredients}
          topIngredientsNumber={1}
        />

        <SingleChoiceQuestion
          questionTitle={"Which are you?"}
          titleLines={props.titleLines}
          nextInnerStep={props.nextInnerStep}
          previousInnerStep={props.previousInnerStep}
          userScores={props.userScores}
          updateUserScores={props.updateUserScores}
          updateUserResponses={props.updateUserResponses}
          step={step}
          question={'Q3'}
          options={[
            {
              responseText: 'Female',
            },
            {
              responseText: 'Male',
            },
            {
              responseText: 'Non-binary',
            },
            {
              responseText: 'Prefer not to say',
            },
          ]}
          getRecommendedIngredients={props.getRecommendedIngredients}
          topIngredientsNumber={1}
        />
      </>

    );
  }
  if (width < breakpoint) {
    return (<>
      <InputQuestion
        isInvisible={!(props.isOnStep === 1 && props.step1InnerCount === 1)}
        questionTitle={'How should we call you?'}
        placeholder={'Name or nickname'}
        buttonText={'Next'}
        nextInnerStep={props.nextInnerStep}
        question={'Q1'}
        step={step}
        onInputChange={props.getUserName}
        isRequired={true}
        length={[2, 15]}
        getUserName={props.getUserName}
        isNameEmpty={props.isNameEmpty}
      />
      <SingleChoiceQuestion
        isInvisible={!(props.isOnStep === 1 && props.step1InnerCount === 2)}
        questionTitle={"How old are you?"}
        titleLines={props.titleLines}
        nextInnerStep={props.nextInnerStep}
        previousInnerStep={props.previousInnerStep}
        userScores={props.userScores}
        updateUserScores={props.updateUserScores}
        updateUserResponses={props.updateUserResponses}
        step={step}
        question={'Q2'}
        options={[
          {
            responseText: '20 or below',
          },
          {
            responseText: '21 - 30',
          },
          {
            responseText: '31 - 40',
            score: {
              Aging: 1
            }
          },
          {
            responseText: '41 - 50',
            score: {
              Aging: 2
            }
          },
          {
            responseText: '51 - 60',
            score: {
              Aging: 3
            }
          },
          {
            responseText: '61 and up',
            score: {
              Aging: 4
            }
          },
          {
            responseText: 'Prefer not to say',
            score: {
              Aging: 0
            }
          },
        ]}
        getRecommendedIngredients={props.getRecommendedIngredients}
        topIngredientsNumber={1}
      />
      <SingleChoiceQuestion
        isInvisible={!(props.isOnStep === 1 && props.step1InnerCount === 3)}
        questionTitle={"Which are you?"}
        titleLines={props.titleLines}
        nextInnerStep={props.nextInnerStep}
        previousInnerStep={props.previousInnerStep}
        userScores={props.userScores}
        updateUserScores={props.updateUserScores}
        updateUserResponses={props.updateUserResponses}
        step={step}
        question={'Q3'}
        options={[

          {
            responseText: 'Female',
          },
          {
            responseText: 'Non-binary',
          },
          {
            responseText: 'Male',
          },
          {
            responseText: 'Prefer not to say',
          },
        ]}
        getRecommendedIngredients={props.getRecommendedIngredients}
        topIngredientsNumber={1}
      />
    </>
    );
  }
}
