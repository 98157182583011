import BestIngredients from "../BestIngredients/BestIngredients";
// import ImagesIngredients from '../ImagesIngredients/ImagesIngredients';
import RecommendedSet from "../RecommendedSet/RecommendedSet";
import RecommendedProduct from "../RecommendedProduct/RecommendedProduct";
import SmoothieTime from "../SmoothieTime/SmoothieTime";
import Cart from "../Cart/Cart";
import { appendDiscount } from "../../utils/CartUtils";
import { useState, useEffect, useRef, useCallback } from "react";

const ImgUrl = `https://aiodefilesbucket.s3.amazonaws.com/freprod/{id}.webp`;

export default function PersonalRecommendation(props) {
  const recommendedProducts = props.recommendedProducts; // 5 recommended products
  const personalSentences = props.personalSentences; // 3 personalSentences for each product.
  const bestMatchSentences = props.bestMatchSentences;
  console.log("recommendedProducts", props.recommendedProducts);
  // console.log("personalSentences", personalSentences);

  // Declare the state variable [[],[],[],[]]
  const [ingredientsProductArray, setIngredientsProductArray] = useState([]);
  const [usageArray, setUsageArray] = useState([]);
  const [imagesSetProducts, setImagesSetProducts] = useState([]);
  //store all of the recommended Products cart IDs
  const [cartID, setCartID] = useState([]);
  // products prices for recomnded set
  const [productPrices, setProductPrices] = useState(0);

  const [price, setPrice] = useState(0.0);
  const [itemCount, setItemCount] = useState(0);
  const [cartUrl, setCartUrl] = useState("https://www.freskincare.com/cart/");
  const [discount, setDiscount] = useState(20);

  // Get ingredientsForProduct Array - ingredientsIDForProduct
  useEffect(() => {
    if (recommendedProducts !== undefined) {
      const ingredients = recommendedProducts.map(
        (product) => product.ingredientsIDForProduct
      );
      const usages = recommendedProducts.map((product) =>
        product.Usage.split("\n")
      );
      const imagesID = recommendedProducts.map((product) =>
        product.ID.split("\n")
      );

      const cartIDArray = recommendedProducts.map(
        (product) => product.Variant_ID
      );

      recommendedProducts.map((product) => {
        if (product.Price) {
          setProductPrices((prevPrice) => prevPrice + product.Price);
        }
      });

      setCartID(cartIDArray);
      setIngredientsProductArray(ingredients);
      setUsageArray(usages);
      setImagesSetProducts(imagesID);
      // console.log("ingredients", ingredients);
      // console.log("usageArray", usages);
    }
  }, [recommendedProducts]);

  const productRefs = useRef({});
  const addToRefs = useCallback((el, id) => {
    productRefs.current[id] = el;
  }, []);

  const scrollToProduct = (productID) => {
    productRefs.current?.[productID]?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const goToCheckout = (e) => {
    e.stopPropagation();
    window.open(appendDiscount(discount, cartUrl));
  };

  const productComponents = recommendedProducts.map((product, index) => {
    let ingredientsProduct = ingredientsProductArray[index];
    let usageProduct = usageArray[index];
    let personalSentencesProduct = personalSentences[index];
    let bestMatchSentencesProduct = bestMatchSentences[index];
    // console.log('Product Ingredients:', ingredientsProduct);
    // console.log('Product usageProduct:', usageProduct);

    return (
      <div
        key={product.ID}
        ref={(el) => {
          addToRefs(el, product.ID);
        }}
      >
        <RecommendedProduct
          isOnStep={props.isOnStep}
          productTitle={product.Name}
          productImage={ImgUrl.replace("{id}", product.ID)}
          productInfo1={product.Description}
          price={product.Price}
          setPrice={setPrice} //cart price
          cartID={product.Variant_ID}
          cartUrl={cartUrl}
          setCartUrl={setCartUrl}
          setItemCount={setItemCount}
          isButtonActive={props.isButtonActive}
          ingredientsProduct={ingredientsProduct} // Pass the relevant ingredients as a prop
          personalSentences={personalSentencesProduct}
          discount={discount}
          bestMatchSentences={bestMatchSentencesProduct}
          stepTitle={product.stepTitle}
        >
          {usageProduct &&
            usageProduct.map((usageStep, usageIndex) => {
              return (
                <li
                  key={usageIndex}
                  className="recommendend-product-small-text recommendend-product-small-text_no-padding"
                >
                  {usageStep}
                </li>
              );
            })}
        </RecommendedProduct>
      </div>
    );
  });

  if (props.isOnStep === 5) {
    return (
      <div className=".personal-recommendation-container">
        <Cart
          price={price}
          itemCount={itemCount}
          goToCheckout={goToCheckout}
          cartUrl={cartUrl}
        />

        <div className="personal-recommendation">
          <div className="personal-recommendation-skin-summery">
            {/* <div className="personal-recommendation-skin-summery-title">
            Your skin summary:
          </div> */}
            {/* <div className="personal-recommendation-skin-summery-text">
            Skin type: [Combination]
          </div> */}
            {/* <div className="personal-recommendation-skin-summery-text">
            Aspects to focus on: [Daily care], [Wrinkles], [Blood vessels]{' '}
          </div> */}
          </div>

          <div className="personal-recommendation-set-recommendation">
            <div className="personal-recommendation-skin-summery-title personal-recommendation_set">
              Your recommended FRÉ products are here!
            </div>
            <div className="personal-recommendation-skin-summery">
              <p className="personal-recommendation-skin-summery_text">
                {
                  "Try these clean, high-performance products for healthy, glowing skin."
                }
              </p>
              {/* TODO - for future use
            <div className=" personal-recommendation-skin-summery-container">
              <h3 className="personal-recommendation-skin-summery_title">
                {"Basis"}
              </h3>
              <p className="personal-recommendation-skin-summery_text">
                {
                  "This set includes the best fitting products for your skin used for minimal daily care."
                }
              </p>
            </div>
            <div className="personal-recommendation-skin-summery-container">
              <h3 className="personal-recommendation-skin-summery_title">
                {"Extensions"}
              </h3>
              <p className="personal-recommendation-skin-summery_text">
                {
                  "These are extra products that are perfect for your skin type and complement the basic set for better results."
                }
              </p>
              </div>*/}
            </div>
            <RecommendedSet
              imagesSetProducts={imagesSetProducts}
              isButtonActive={props.isButtonActive}
              isOnStep={props.isOnStep}
              scrollToProduct={scrollToProduct}
              cartID={cartID}
              cartUrl={cartUrl}
              setCartUrl={setCartUrl}
              setItemCount={setItemCount}
              setPrice={setPrice}
              productPrices={productPrices}
              discount={discount}
              setDiscount={setDiscount}
            />
          </div>
          {productComponents}
          <div className="personal-recommendation-set-recommendation personal-recommendation-set-recommendation_end">
            <div className="personal-recommendation-grid-align"></div>
            <RecommendedSet
              imagesSetProducts={imagesSetProducts}
              isButtonActive={props.isButtonActive}
              isOnStep={props.isOnStep}
              scrollToProduct={scrollToProduct}
              cartID={cartID}
              cartUrl={cartUrl}
              setCartUrl={setCartUrl}
              setItemCount={setItemCount}
              setPrice={setPrice}
              productPrices={productPrices}
              discount={discount}
              setDiscount={setDiscount}
            />
          </div>

          <BestIngredients
            recommendedIngredients={props.recommendedIngredients}
          />
        </div>
      </div>
    );
  }
}
