import bakuchiol from "../../images/bakuchiol.png";
import argania from "../../images/argania.png";
import arganOil from "../../images/argan_oil.png";
import Button from "../Button/Button";
import { useEffect, useState } from "react";
import { fetchProductScores } from "../../store/product-scores/thunks";
import { productScoresSelector } from "../../store/product-scores/selectors";

import { fetchIngredients, ingredientsSelector } from "../../store/ingredients";
//import { useDispatch, useSelector } from "react-redux";

export default function IntermediateResult(props) {
  //const dispatch = useDispatch();
  //const productScoresData = useSelector(productScoresSelector);
  //const ingredientsData = useSelector(ingredientsSelector);
  const [ingredients, setIngredients] = useState([]);
  const [productScores, setProductScores] = useState([]);

  /*useEffect(() => {
    //  dispatch(fetchIngredients());
    //  dispatch(fetchProductScores());
  }, [dispatch]);*/

  // console.log("ingredients", ingredientsData);
  // console.log("productScores", productScoresData);

  if (props.isOnStep === 2.5) {
    return (
      <div className="intermediate-result">
        <div className="intermediate-result-text">
          According to the intermediate result, these ingredients scored the
          most points.
        </div>
        <div className="intermediate-result-ing-container">
          <div className="intermediate-result-ing">
            <img
              className="intermediate-result-ing-image"
              src={bakuchiol}
              alt={"bakuchiol"}
            ></img>
            <p className="intermediate-result-ing-text">Bakuchiol</p>
          </div>
          <div className="intermediate-result-ing">
            <img className="intermediate-result-ing-image" src={argania}></img>
            <p className="intermediate-result-ing-text">Argania</p>
          </div>
          <div className="intermediate-result-ing">
            <img className="intermediate-result-ing-image" src={arganOil}></img>
            <p className="intermediate-result-ing-text">Argan Oil</p>
          </div>
        </div>
        <div className="intermediate-result-text">
          Let's see if these are really the best ingredients for you.
        </div>
        <Button
          isButtonActive={props.isButtonActive}
          buttonText={"next"}
          onClick={props.closeIntermediateResult}
        />
      </div>
    );
  }
}
