import CounterBlock from '../CounterBlock/CounterBlock';
import useScreenSize from '../../utils/useScreenSize';
import './StepCounter.css';

export default function StepCounter(props) {
  const [width, breakpoint] = useScreenSize();
  if (props.steps === 5) {
    return (
      <div className="step-counter">
        <div className="step-counter-progress-container">
          { width >= breakpoint && <div className="step-counter-title">{'step ' + props.stepCount}</div> }
          <div className="step-counter-progress-bar-container">
          <CounterBlock
            openedQuestions={props.openedQuestions}
              innerBlockOrder={1}
              moveStep={props.moveStep}
              stepCount={props.stepCount}
              isOnStep={props.isOnStep}
              innerStepCount={props.innerStepCount}
            />
            <CounterBlock
              openedQuestions={props.openedQuestions}
              innerBlockOrder={2}
              moveStep={props.moveStep}
              stepCount={props.stepCount}
              isOnStep={props.isOnStep}
              innerStepCount={props.innerStepCount}
            />
            <CounterBlock
              openedQuestions={props.openedQuestions}
              innerBlockOrder={3}
              moveStep={props.moveStep}
              stepCount={props.stepCount}
              isOnStep={props.isOnStep}
              innerStepCount={props.innerStepCount}
            />
            <CounterBlock
              openedQuestions={props.openedQuestions}
              innerBlockOrder={4}
              moveStep={props.moveStep}
              stepCount={props.stepCount}
              isOnStep={props.isOnStep}
              innerStepCount={props.innerStepCount}
            />
            <CounterBlock
              openedQuestions={props.openedQuestions}
              innerBlockOrder={5}
              moveStep={props.moveStep}
              stepCount={props.stepCount}
              isOnStep={props.isOnStep}
              innerStepCount={props.innerStepCount}
            />
          </div>
        </div>
      </div>
    );
  }
  if (props.steps === 3) {
    return (
      <div className="step-counter">
        <div className="step-counter-progress-container">
        { width >= breakpoint && <div className="step-counter-title">{'step ' + props.stepCount}</div> }
          <div className="step-counter-progress-bar-container">
          <CounterBlock
            openedQuestions={props.openedQuestions}
              innerBlockOrder={1}
              moveStep={props.moveStep}
              stepCount={props.stepCount}
              isOnStep={props.isOnStep}
              innerStepCount={props.innerStepCount}
            />
            <CounterBlock
              openedQuestions={props.openedQuestions}
              innerBlockOrder={2}
              moveStep={props.moveStep}
              stepCount={props.stepCount}
              isOnStep={props.isOnStep}
              innerStepCount={props.innerStepCount}
            />
            <CounterBlock
              openedQuestions={props.openedQuestions}
              innerBlockOrder={3}
              moveStep={props.moveStep}
              stepCount={props.stepCount}
              isOnStep={props.isOnStep}
              innerStepCount={props.innerStepCount}
            />
          </div>
        </div>
      </div>
    );
  }
  if (props.steps === 4) {
    return (
      <div className="step-counter">
        <div className="step-counter-progress-container">
        { width >= breakpoint && <div className="step-counter-title">{'step ' + props.stepCount}</div> }
          <div className="step-counter-progress-bar-container">
          <CounterBlock
            openedQuestions={props.openedQuestions}
              innerBlockOrder={1}
              moveStep={props.moveStep}
              stepCount={props.stepCount}
              isOnStep={props.isOnStep}
              innerStepCount={props.innerStepCount}
            />
            <CounterBlock
              openedQuestions={props.openedQuestions}
              innerBlockOrder={2}
              moveStep={props.moveStep}
              stepCount={props.stepCount}
              isOnStep={props.isOnStep}
              innerStepCount={props.innerStepCount}
            />
            <CounterBlock
              openedQuestions={props.openedQuestions}
              innerBlockOrder={3}
              moveStep={props.moveStep}
              stepCount={props.stepCount}
              isOnStep={props.isOnStep}
              innerStepCount={props.innerStepCount}
            />
            <CounterBlock
              openedQuestions={props.openedQuestions}
              innerBlockOrder={4}
              moveStep={props.moveStep}
              stepCount={props.stepCount}
              isOnStep={props.isOnStep}
              innerStepCount={props.innerStepCount}
            />
          </div>
        </div>
      </div>
    );
  }
  if (props.steps === 5) {
    return (
      <div className="step-counter">
        <div className="step-counter-progress-container">
          <div className="step-counter-title">{'step ' + props.stepCount}</div>
          <div className="step-counter-progress-bar-container">
          <CounterBlock
            openedQuestions={props.openedQuestions}
              innerBlockOrder={1}
              moveStep={props.moveStep}
              stepCount={props.stepCount}
              isOnStep={props.isOnStep}
              innerStepCount={props.innerStepCount}
            />
            <CounterBlock
              openedQuestions={props.openedQuestions}
              innerBlockOrder={2}
              moveStep={props.moveStep}
              stepCount={props.stepCount}
              isOnStep={props.isOnStep}
              innerStepCount={props.innerStepCount}
            />
            <CounterBlock
              openedQuestions={props.openedQuestions}
              innerBlockOrder={3}
              moveStep={props.moveStep}
              stepCount={props.stepCount}
              isOnStep={props.isOnStep}
              innerStepCount={props.innerStepCount}
            />
            <CounterBlock
              openedQuestions={props.openedQuestions}
              innerBlockOrder={4}
              moveStep={props.moveStep}
              stepCount={props.stepCount}
              isOnStep={props.isOnStep}
              innerStepCount={props.innerStepCount}
            />
            <CounterBlock
              openedQuestions={props.openedQuestions}
              innerBlockOrder={5}
              moveStep={props.moveStep}
              stepCount={props.stepCount}
              isOnStep={props.isOnStep}
              innerStepCount={props.innerStepCount}
            />
          </div>
        </div>
      </div>
    );
  }
}
