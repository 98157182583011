import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { useState } from 'react';

export default function IntroInput(props) {
  const [isEmailFilled, setIsEmailFilled] = useState(true);
  const [email, setEmail] = useState('');

  function handleSubmit(e) {
    e.preventDefault();
    props.onClick();
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (email.length > 0) {
      setIsEmailFilled(true);
      props.onClick();
    } else {
      setIsEmailFilled(false);
    }
  }

  function handleChangeInput(e) {
    setEmail(e.target.value);
    props.onChange(e);
  }

  return (
    <form
      className="input intro-input"
      onSubmit={handleSubmit}
    >
      <div className="input-box-container_intro">
        <input
          className="input-box input-box_intro"
          placeholder={props.placeholder}
          required={props.isRequired}
          onChange={handleChangeInput}
          type='email'
          autoFocus
        />
        <span className="input-box_error" style={{opacity: isEmailFilled && 0}}>Please enter your email</span>
        {/* {!isEmailFilled && (<span className="input-box_error">Please enter your email</span>)} */}
      </div>


      {props.isResultReady
        ? <button
          type='submit'
          className='button intro-input-button'>
          {props.buttonText}
        </button>
        : <LoadingSpinner />}


    </form>
  )
}