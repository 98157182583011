import StepsCounters from "../StepsCounters/StepsCounters";
import StepCountersMobile from "../StepCountersMobile/StepCountersMobile"
import Step1 from "../Step1/Step1";
import Step2 from "../Step2/Step2";
import Step3 from "../Step3/Step3";
import Step4 from "../Step4/Step4";
import StepCountersInactive from "../StepCountersInactive/StepCountersInactive";
import IntermediateResult from "../IntermediateResult/IntermediateResult";
import renderTopIngredients from "../../utils/renderTopIngredients";
import useScreenSize from "../../utils/useScreenSize";
import BackgroundImage from "../BackgroundImage/BackgroundImage";
import FooterLogo from "../FooterLogo/FooterLogo";
import { useState, useEffect } from 'react';

export default function Main(props) {
  const [width, breakpoint] = useScreenSize();
  const [bgImg, setBgImg] = useState(-1);

  useEffect(() => {
    if (bgImg < 10) {
      setBgImg(bgImg + 1);
    } else {
      setBgImg(1)
    }
  }, [props.isOnStep,
  props.step1InnerCount,
  props.step2InnerCount,
  props.step3InnerCount,
  props.step4InnerCount,
  props.bonusCounter])

  function selectQItems(stepKey) {
    const selectedItems = props.AllQtipsFunc.filter(item =>
      item.step === stepKey
    );
    return selectedItems;
  }

  if (props.isOnStep !== 4.5) return (
    <div className="main">
      {width >= 900 && props.isOnStep > 0 && props.isOnStep <= 4 &&
        <>
          <BackgroundImage
            srcName={'bg01.jpg'}
            className='background_bg1'
            style={{ opacity: bgImg === 1 ? '1' : '0' }} />
          <BackgroundImage
            srcName={'bg02.jpg'}
            className='background_bg2'
            style={{ opacity: bgImg === 2 ? '1' : '0' }} />
          <BackgroundImage
            srcName={'bg03.jpg'}
            className='background_bg3'
            style={{ opacity: bgImg === 3 ? '1' : '0' }} />
          <BackgroundImage
            srcName={'bg04.jpg'}
            className='background_bg4'
            style={{ opacity: bgImg === 4 ? '1' : '0' }} />
          <BackgroundImage
            srcName={'bg05.jpg'}
            className='background_bg5'
            style={{ opacity: bgImg === 5 ? '1' : '0' }} />
          <BackgroundImage
            srcName={'bg06.jpg'}
            className='background_bg6'
            style={{ opacity: bgImg === 6 ? '1' : '0' }} />
          <BackgroundImage
            srcName={'bg07.jpg'}
            className='background_bg7'
            style={{ opacity: bgImg === 7 ? '1' : '0' }} />
          <BackgroundImage
            srcName={'bg08.jpg'}
            className='background_bg8'
            style={{ opacity: bgImg === 8 ? '1' : '0' }} />
          <BackgroundImage
            srcName={'bg09.jpg'}
            className='background_bg9'
            style={{ opacity: bgImg === 9 ? '1' : '0' }} />
          <BackgroundImage
            srcName={'bg10.jpg'}
            className='background_bg10'
            style={{ opacity: bgImg === 10 ? '1' : '0' }} />
        </>
      }

      <div className="main-container">
        {width >= breakpoint &&
          <StepsCounters
            openedQuestions={props.openedQuestions}
            moveStep={props.moveStep}
            isOnStep={props.isOnStep}
            step1InnerCount={props.step1InnerCount}
            step2InnerCount={props.step2InnerCount}
            step3InnerCount={props.step3InnerCount}
            step4InnerCount={props.step4InnerCount}
          />
        }
        {(width < breakpoint && [1, 2, 3, 4].includes(props.isOnStep)) &&
          <StepCountersMobile
            openedQuestions={props.openedQuestions}
            moveStep={props.moveStep}
            isOnStep={props.isOnStep}
            step1InnerCount={props.step1InnerCount}
            step2InnerCount={props.step2InnerCount}
            step3InnerCount={props.step3InnerCount}
            step4InnerCount={props.step4InnerCount}
          />
        }

        {([1, 2, 3, 4].includes(props.isOnStep) && width >= breakpoint)
          && <div
            className="main-questions-container"
            style={props.containerStyle}
          >
            <Step1
              getRecommendedIngredients={props.getRecommendedIngredients}
              step1InnerCount={props.step1InnerCount}
              isButtonActive={props.isButtonActive}
              isOnStep={props.isOnStep}
              isOnMultiSelectResponses={props.isOnMultiSelectResponses}
              isResponseSelected={props.isResponseSelected}
              nextStep={props.nextStep}
              previousInnerStep={props.previousInnerStep}
              containerStyle={props.containerStyle}
              nextInnerStep={props.nextInnerStep}
              userScores={props.userScores}
              updateUserScores={props.updateUserScores}
              responses={props.responses}
              updateUserResponses={props.updateUserResponses}
              recommendedIngredients={props.recommendedIngredients}
              getUserName={props.getUserName}
              name={props.name}
              handleSaveUserResponsesMulti={props.handleSaveUserResponsesMulti}
              isNameEmpty={props.isNameEmpty}
            />

            <Step2
              AllQtipsFunc={selectQItems("step2")}
              getRecommendedIngredients={props.getRecommendedIngredients}
              step2InnerCount={props.step2InnerCount}
              isButtonActive={props.isButtonActive}
              isOnStep={props.isOnStep}
              titleLines={2}
              isOnMultiSelectResponses={props.isOnMultiSelectResponses}
              nextStep={props.intermediateResult}
              previousInnerStep={props.previousInnerStep}
              containerStyle={props.containerStyle}
              nextInnerStep={props.nextInnerStep}
              previousStep={props.previousStep}
              userScores={props.userScores}
              updateUserScores={props.updateUserScores}
              responses={props.responses}
              updateUserResponses={props.updateUserResponses}
              recommendedIngredients={props.recommendedIngredients}
              changeNoneToNext={props.changeNoneToNext}
              setChangeNoneToNext={props.setChangeNoneToNext}
              handleSaveUserResponsesMulti={props.handleSaveUserResponsesMulti}
            />

            <Step3
              AllQtipsFunc={selectQItems("step3")}
              containerStyleBonus={props.containerStyleBonus}
              questionWidth={props.questionWidth}
              getRecommendedIngredients={props.getRecommendedIngredients}
              isButtonActive={props.isButtonActive}
              step3InnerCount={props.step3InnerCount}
              isOnStep={props.isOnStep}
              titleLines={2}
              isOnMultiSelectResponses={props.isOnMultiSelectResponses}
              isOnImgMultiSelectResponses={props.isOnImgMultiSelectResponses}
              nextStep={props.nextStep}
              previousInnerStep={props.previousInnerStep}
              containerStyle={props.containerStyle}
              containerStyleY={props.containerStyleY}
              nextInnerStep={props.nextInnerStep}
              userScores={props.userScores}
              updateUserScores={props.updateUserScores}
              responses={props.responses}
              updateUserResponses={props.updateUserResponses}
              recommendedIngredients={props.recommendedIngredients}
              isBonusQuestion={props.isBonusQuestion}
              isBonusQuestionActive={props.isBonusQuestionActive}
              displayBonusQuestion={props.displayBonusQuestion}
              innerStepOrBonusHandler={props.innerStepOrBonusHandler}
              nextBonusStepHandler={props.nextBonusStepHandler}
              backFromBonusQuestion={props.backFromBonusQuestion}
              displayPigmentationQuestions={props.displayPigmentationQuestions}
              displayAcneQuestions={props.displayAcneQuestions}
              displayRednessQuestions={props.displayRednessQuestions}
              activatePigmentationQuestions={props.activatePigmentationQuestions}
              activateAcneQuestions={props.activateAcneQuestions}
              activateRednessQuestions={props.activateRednessQuestions}
              nextBonusQuestion={props.nextBonusQuestion}
              returnFromBonusQuestion={props.returnFromBonusQuestion}
              changeNoneToNext={props.changeNoneToNext}
              setChangeNoneToNext={props.setChangeNoneToNext}
              handleSaveUserResponsesMulti={props.handleSaveUserResponsesMulti}
              previousBonusQuestion={props.previousBonusQuestion}
              bonusCounter={props.bonusCounter}
              setBonusCounter={props.setBonusCounter}
            />

            <Step4
              AllQtipsFunc={selectQItems("step4")}
              getRecommendedIngredients={props.getRecommendedIngredients}
              isButtonActive={props.isButtonActive}
              step4InnerCount={props.step4InnerCount}
              isOnStep={props.isOnStep}
              titleLines={2}
              isOnMultiSelectResponses={props.isOnMultiSelectResponses}
              isOnImgMultiSelectResponses={props.isOnImgMultiSelectResponses}
              displaySmoothiePage={props.displaySmoothiePage}
              previousInnerStep={props.previousInnerStep}
              containerStyle={props.containerStyle}
              nextInnerStep={props.nextInnerStep}
              userScores={props.userScores}
              updateUserScores={props.updateUserScores}
              responses={props.responses}
              updateUserResponses={props.updateUserResponses}
              recommendedIngredients={props.recommendedIngredients}
              handleSaveUserResponsesMulti={props.handleSaveUserResponsesMulti}
            />
          </div>}
        {([1, 2, 3, 4].includes(props.isOnStep) && width < breakpoint) &&
          <>
            <Step1
              getRecommendedIngredients={props.getRecommendedIngredients}
              step1InnerCount={props.step1InnerCount}
              isButtonActive={props.isButtonActive}
              isOnStep={props.isOnStep}
              isOnMultiSelectResponses={props.isOnMultiSelectResponses}
              isResponseSelected={props.isResponseSelected}
              nextStep={props.nextStep}
              previousInnerStep={props.previousInnerStep}
              containerStyle={props.containerStyle}
              nextInnerStep={props.nextInnerStep}
              userScores={props.userScores}
              updateUserScores={props.updateUserScores}
              responses={props.responses}
              updateUserResponses={props.updateUserResponses}
              recommendedIngredients={props.recommendedIngredients}
              getUserName={props.getUserName}
              name={props.name}
              handleSaveUserResponsesMulti={props.handleSaveUserResponsesMulti}
              isNameEmpty={props.isNameEmpty}
            />

            <Step2
              AllQtipsFunc={selectQItems("step2")}
              getRecommendedIngredients={props.getRecommendedIngredients}
              step2InnerCount={props.step2InnerCount}
              isButtonActive={props.isButtonActive}
              isOnStep={props.isOnStep}
              titleLines={2}
              isOnMultiSelectResponses={props.isOnMultiSelectResponses}
              nextStep={props.intermediateResult}
              previousInnerStep={props.previousInnerStep}
              containerStyle={props.containerStyle}
              nextInnerStep={props.nextInnerStep}
              previousStep={props.previousStep}
              userScores={props.userScores}
              updateUserScores={props.updateUserScores}
              responses={props.responses}
              updateUserResponses={props.updateUserResponses}
              recommendedIngredients={props.recommendedIngredients}
              changeNoneToNext={props.changeNoneToNext}
              setChangeNoneToNext={props.setChangeNoneToNext}
              handleSaveUserResponsesMulti={props.handleSaveUserResponsesMulti}
            />

            <Step3
              AllQtipsFunc={selectQItems("step3")}
              questionWidth={props.questionWidth}
              getRecommendedIngredients={props.getRecommendedIngredients}
              isButtonActive={props.isButtonActive}
              step3InnerCount={props.step3InnerCount}
              isOnStep={props.isOnStep}
              titleLines={2}
              isOnMultiSelectResponses={props.isOnMultiSelectResponses}
              isOnImgMultiSelectResponses={props.isOnImgMultiSelectResponses}
              nextStep={props.nextStep}
              previousInnerStep={props.previousInnerStep}
              containerStyle={props.containerStyle}
              containerStyleY={props.containerStyleY}
              nextInnerStep={props.nextInnerStep}
              userScores={props.userScores}
              updateUserScores={props.updateUserScores}
              responses={props.responses}
              updateUserResponses={props.updateUserResponses}
              recommendedIngredients={props.recommendedIngredients}
              isBonusQuestion={props.isBonusQuestion}
              isBonusQuestionActive={props.isBonusQuestionActive}
              displayBonusQuestion={props.displayBonusQuestion}
              innerStepOrBonusHandler={props.innerStepOrBonusHandler}
              nextBonusStepHandler={props.nextBonusStepHandler}
              backFromBonusQuestion={props.backFromBonusQuestion}
              displayPigmentationQuestions={props.displayPigmentationQuestions}
              displayAcneQuestions={props.displayAcneQuestions}
              displayRednessQuestions={props.displayRednessQuestions}
              activatePigmentationQuestions={props.activatePigmentationQuestions}
              activateAcneQuestions={props.activateAcneQuestions}
              activateRednessQuestions={props.activateRednessQuestions}
              nextBonusQuestion={props.nextBonusQuestion}
              returnFromBonusQuestion={props.returnFromBonusQuestion}
              changeNoneToNext={props.changeNoneToNext}
              setChangeNoneToNext={props.setChangeNoneToNext}
              handleSaveUserResponsesMulti={props.handleSaveUserResponsesMulti}
              previousBonusQuestion={props.previousBonusQuestion}
            />

            <Step4
              AllQtipsFunc={selectQItems("step4")}
              getRecommendedIngredients={props.getRecommendedIngredients}
              isButtonActive={props.isButtonActive}
              step4InnerCount={props.step4InnerCount}
              isOnStep={props.isOnStep}
              titleLines={2}
              isOnMultiSelectResponses={props.isOnMultiSelectResponses}
              isOnImgMultiSelectResponses={props.isOnImgMultiSelectResponses}
              displaySmoothiePage={props.displaySmoothiePage}
              previousInnerStep={props.previousInnerStep}
              containerStyle={props.containerStyle}
              nextInnerStep={props.nextInnerStep}
              userScores={props.userScores}
              updateUserScores={props.updateUserScores}
              responses={props.responses}
              updateUserResponses={props.updateUserResponses}
              recommendedIngredients={props.recommendedIngredients}
              handleSaveUserResponsesMulti={props.handleSaveUserResponsesMulti}
            />
          </>
        }

        {(width >= breakpoint) && <StepCountersInactive openedQuestions={props.openedQuestions} isOnStep={props.isOnStep} moveStep={props.moveStep} />}
        {(width < breakpoint && props.isOnStep !== 0) && renderTopIngredients(props)}

        {(props.isOnStep !== 0 && props.isOnStep !== 5 && width > breakpoint) && <FooterLogo class='main-logo' />}

        <IntermediateResult
          isOnStep={props.isOnStep}
          isButtonActive={props.isButtonActive}
          nextStep={props.nextStep}
          closeIntermediateResult={props.closeIntermediateResult}
        />
      </div>
    </div>
  );
}
