import { footer_logo } from '../../utils/constants';

export default function FooterLogo(props) {
  return (
    <div className={`footer-logo ${props.class}`}>
      <p className="footer-logo-text">Powered by</p>
      <a className="footer-logo-link" href="https://www.menow.ai/" target="_blank">
        <img className="footer-logo-logo" src={ footer_logo } />
      </a>
    </div>
  )
}