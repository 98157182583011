import api from "./axios.js"

export async function getAllQtips() {
    try{
    const response = await api.get('/q_tips');
    return response.data;
    }catch(error){ 
      console.log('error in getQtips: ' + error.message);
      return [];
    }        
 }

/* Get all the ingredients for top ingredients for top-ingredients 
     element on the first screen*/ 
export async function getAllIngredients() {
    try{
    const response = await api.get("/product-scores/ingredients");
    return response.data;
    }catch(error){ 
      console.log("error in getIngredients:" + error.message)
      return [];
    };
    
  }
 
  
  /* Getting a recommendation on ingredients - a reply from the Backend
      returning array of 3 ingredient. */
 export async function getRecommendedIngredients(userScores) {
    let stepp = 3;
    try{
    const response = await api.post("/recommend-ingredients", { userScores, step: stepp });
    return response.data;
    }catch(error){ 
      console.log("error in getIngredients: " + error.message)
      return {};
    }; 

  }

  export async function getProducts(userScores,responses) {
    try{
        const response = await api.post("/recommend-products", { userScores, responses });
        return response.data;
    }catch(error){ 
      console.log("error in get Products: " + error.message)
      return {};
    }; 
      


}   

export async function getSmoothies(userScores) {
    try{
        const response = await api.post("/recommend-smoothies", userScores);
        return response.data;
    }catch(error){ 
      console.log("error in getIngredients: " + error.message);
      return {};
    };
    
}


export function sendEmail(email,recommendedProducts) {
    api
      .post('/send-email', {email:email,products:recommendedProducts})
      .catch((error) => 'error in sendEmail: ' + error.message);
  }

  export function klaviyoConnection(email,recommendedProducts){
    api
    .post('/klaviyo-connection',{email:email,products:recommendedProducts})
    .catch((error) => 'error in klaviyoConnection: ' + error.message);
  }
  
     


  