import IngredientInfo from '../IngredientInfo/IngredientInfo';
import IngredientCarousel from '../IngredientCarousel/IngredientCarousel';

const ImgUrl = `https://aiodefilesbucket.s3.amazonaws.com/ingimages/{id}.jpg`


export default function BestIngredients(props) {
  const ingredientComponents = props.recommendedIngredients.map((ingredient) => (
    <div key={ingredient.id}>
      <IngredientInfo 
        ingredientImage={ImgUrl.replace('{id}', ingredient.id)}
        ingredientTitle={ingredient.name}
        paragraph={ingredient.description}
        name={ingredient.name}
      />
    </div>


  ));

  if (window.innerWidth > 768){
    return (
      <div className="best-ingredients">
        <div className="best-ingredients-title">Your best FRÉ ingredients:</div>
        <div className="best-ingredients-info-container">
          {ingredientComponents}
        </div>
      </div>
    );
  }
  else {
    return (
      <div className="best-ingredients">
        <IngredientCarousel recommendedIngredients={props.recommendedIngredients} />
      </div>
    );
  }
}
