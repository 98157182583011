import { useState } from "react";
import BonusQuestion from "../BonusQuestion/BonusQuestion";
import BonusQuestionsContainer from "../BonusQuestionsContainer/BonusQuestionsContainer";
import ImagesQuestion from '../ImagesQuestion/ImagesQuestion';
import useScreenSize from "../../utils/useScreenSize";
import MultiChoiceQuestion from "../MultiChoiceQuestion/MultiChoiceQuestion";
import SingleChoiceQuestion from "../SingleChoiceQuestion/SingleChoiceQuestion";
import SliderQuestion from "../SliderQuestion/SliderQuestion";
import TipPopup from "../TipPopup/TipPopup";

import {
  marks,
  BlueYellowSliderStyles,
  dark_spots,
  discoloration,
} from '../../utils/constants';

export default function Step3(props) {
  const step = "Step3"; //TODO: change all to props
  const [width, breakpoint] = useScreenSize();
  const { AllQtipsFunc } = props;

  // Bonus question manipulation for mobile version
  const [bonusQuestionOpen, setBonusQuestionOpen] = useState(1);
  const nextBonusQuestion = () => {
    setBonusQuestionOpen(bonusQuestionOpen + 1);
  };
  const prevBonusQuestion = () => {
    setBonusQuestionOpen(bonusQuestionOpen - 1);
  };
  const resetBonusQuestions = () => {
    setBonusQuestionOpen(1);
  };

  const imagesUrl = 'https://aiodefilesbucket.s3.amazonaws.com/skinconditions';

  function selectQItems(questionKey) {
    const selectedItems = AllQtipsFunc.filter(item =>
      item.question === questionKey
    );
    return selectedItems;
  }

  if (width >= breakpoint) {
    return (
      <>
        {/*///////////////////////////////////QUESTION 1////////////////////////////////////////////*/}
        <BonusQuestion containerStyleY={props.containerStyleY} containerStyleBonus={props.containerStyleBonus}>
          <ImagesQuestion
            width={'540px'}
            previousInnerStep={props.previousInnerStep}
            getRecommendedIngredients={props.getRecommendedIngredients}
            topIngredientsNumber={3}
            questionTitle={'Does your skin show signs of aging? Select all that apply:'}
            isButtonActive={props.isButtonActive}
            titleLines={1}
            nextInnerStep={props.nextInnerStep}
            isOnImgMultiSelectResponses={props.isOnImgMultiSelectResponses}
            activateBonusQuestions={props.activatePigmentationQuestions}
            displayBonusQuestions={props.displayPigmentationQuestions}
            updateUserScores={props.updateUserScores}
            userScores={props.userScores}
            question={'Q1'}
            handleMultiResponses={props.handleSaveUserResponsesMulti}
            step={step}
            options={[
              {
                responseText: 'Sagging skin',
                image: `${imagesUrl}/Sagging_skin.jpg`,
                score: { Aging: 1 },
              },
              {
                responseText: 'Fine lines',
                image: `${imagesUrl}/Fine_lines.jpg`,
                score: { Aging: 1 },
              },
              {
                responseText: 'Wrinkles',
                image: `${imagesUrl}/Wrinkles.jpg`,
                score: { Aging: 1 },
              },
              {
                responseText: 'Pigmentation',
                image: `${imagesUrl}/Pigmentation.jpg`,
                score: { Pigmentation: 1 },
                activateBonus: true
              },
            ]}
          />
          <BonusQuestionsContainer
            // containerStyle={props.containerStyle}
            paddingLeft={(window.innerWidth > 1200 ? '3000px' : (window.innerWidth > 999 ? '2400px' : '2115px'))}
            width={(props.questionWidth * 3)}
            displayBonusQuestions={props.displayPigmentationQuestions}
          >
            <SingleChoiceQuestion
              getRecommendedIngredients={props.getRecommendedIngredients}
              topIngredientsNumber={3}
              questionTitle={"Which pigmentation problem do you suffer from?"}
              titleLines={2}
              nextInnerStep={props.nextInnerStep}
              previousInnerStep={props.previousInnerStep}
              userScores={props.userScores}
              updateUserScores={props.updateUserScores}
              updateUserResponses={props.updateUserResponses}
              step={step}
              question={'Q1'}
              isBonus={true}
              bonusQuestion={'BQ1'}
              nextBonusQuestion={props.nextBonusQuestion}
              previousBonusQuestion={props.previousBonusQuestion}
              returnFromBonusQuestion={props.returnFromBonusQuestion}
              options={[
                {
                  responseText: 'Discoloration',
                  icon: discoloration
                },
                {
                  responseText: 'Dark spots',
                  score: { Pigmentation: 2 },
                  icon: dark_spots
                }
              ]}
              bonusCounter={props.bonusCounter}
              setBonusCounter={props.setBonusCounter}
            />

            <SingleChoiceQuestion
              tip={selectQItems('BQ2')[0]}
              TipPopup={TipPopup}
              getRecommendedIngredients={props.getRecommendedIngredients}
              topIngredientsNumber={3}
              questionTitle={"Are the pigmentation issues related to sun exposure?"}
              titleLines={2}
              nextInnerStep={props.nextInnerStep}
              previousInnerStep={props.previousInnerStep}
              userScores={props.userScores}
              updateUserScores={props.updateUserScores}
              updateUserResponses={props.updateUserResponses}
              step={step}
              question={'Q1'}
              isBonus={true}
              bonusQuestion={'BQ2'}
              nextBonusQuestion={props.nextBonusQuestion}
              previousBonusQuestion={props.previousBonusQuestion}
              options={[
                {
                  responseText: 'Yes',
                  score: { Photoaging: 2 }
                },
                {
                  responseText: 'No'
                },
                {
                  responseText: 'Not sure',
                  score: { Photoaging: 1 }
                }
              ]}
              bonusCounter={props.bonusCounter}
              setBonusCounter={props.setBonusCounter}
            />

            <MultiChoiceQuestion
              getRecommendedIngredients={props.getRecommendedIngredients}
              topIngredientsNumber={3}
              width={'463px'}
              isBonus={true}
              questionTitle={"Which other factors seem to affect the appearance of the spots?"}
              isButtonActive={props.isButtonActive}
              titleLines={props.titleLines}
              nextInnerStep={props.nextInnerStep}
              previousInnerStep={props.previousInnerStep}
              userScores={props.userScores}
              updateUserScores={props.updateUserScores}
              handleMultiResponses={props.handleSaveUserResponsesMulti}
              step={step}
              question={'Q1'}
              bonusQuestion={'BQ3'}
              returnFromBonusQuestion={props.returnFromBonusQuestion}
              previousBonusQuestion={props.previousBonusQuestion}
              isLastBonus={true}
              options={[
                {
                  responseText: 'Hormonal changes',
                  score: {}
                },
                {
                  responseText: 'Stress, fatigue',
                  score: {},
                },
                {
                  responseText: 'Acne scars',
                  score: {}
                },
                {
                  responseText: 'Medical issues',
                  score: {}
                },
              ]}
              bonusCounter={props.bonusCounter}
              setBonusCounter={props.setBonusCounter} />
          </BonusQuestionsContainer>
        </BonusQuestion>

        {/*///////////////////////////////////QUESTION 2////////////////////////////////////////////*/}
        <BonusQuestion containerStyleY={props.containerStyleY} containerStyleBonus={props.containerStyleBonus}>
          <ImagesQuestion
            width={'570px'}
            getRecommendedIngredients={props.getRecommendedIngredients}
            topIngredientsNumber={3}
            previousInnerStep={props.previousInnerStep}
            isOnStep={props.isOnStep}
            nextStep={props.nextStep}
            questionTitle={'Do you suffer from acne? Select all that apply:'}
            isButtonActive={props.isButtonActive}
            titleLines={'test'}
            nextInnerStep={props.nextInnerStep}
            isOnImgMultiSelectResponses={props.isOnImgMultiSelectResponses}
            activateBonusQuestions={props.activateAcneQuestions}
            displayBonusQuestions={props.displayAcneQuestions}
            updateUserScores={props.updateUserScores}
            userScores={props.userScores}
            question={'Q2'}
            handleMultiResponses={props.handleSaveUserResponsesMulti}
            step={step}
            options={[
              {
                responseText: 'Blackheads',
                image: `${imagesUrl}/Blackheads.jpg`,
                score: { Acne: 1 },
              },
              {
                responseText: 'Breakouts',
                image: `${imagesUrl}/Pimples.jpg`,
                score: { Acne: 2 },
                activateBonus: true
              },
              {
                responseText: 'Severe acne',
                image: `${imagesUrl}/Severe_acne.jpg`,
                score: { Acne: 3 },
                activateBonus: true
              },
            ]}
          />
          <BonusQuestionsContainer
            paddingLeft={(window.innerWidth > 1200 ? '3000px' : (window.innerWidth > 999 ? '2400px' : '2115px'))}
            displayBonusQuestions={props.displayAcneQuestions}
            width={(props.questionWidth * 3)}
          >
            <SliderQuestion
              getRecommendedIngredients={props.getRecommendedIngredients}
              topIngredientsNumber={3}
              questionTitle={"How often do you have breakouts?"}
              sliderTextLeft="Only on occasion"
              sliderTextRight="Always & constantly"
              sliderStyle={BlueYellowSliderStyles}
              texts={2}
              marks={marks}
              defaultSliderValue={50}
              nextInnerStep={props.nextInnerStep}
              previousInnerStep={props.previousInnerStep}
              userScores={props.userScores}
              updateUserScores={props.updateUserScores}
              updateUserResponses={props.updateUserResponses}
              returnFromBonusQuestion={props.returnFromBonusQuestion}
              nextBonusQuestion={props.nextBonusQuestion}
              bonusQuestion={'BQ1'}
              isBonus={true}
              step={step}
              question={'Q2'}
              score={{
                0: { Acne: 0 },
                25: { Acne: 0 },
                50: { Acne: 1 },
                75: { Acne: 2 },
                100: { Acne: 3 }
              }}
              bonusCounter={props.bonusCounter}
              setBonusCounter={props.setBonusCounter} />

            <MultiChoiceQuestion
              getRecommendedIngredients={props.getRecommendedIngredients}
              topIngredientsNumber={3}
              width={'487px'}
              questionTitle={"What do you feel causes these changes?"}
              isButtonActive={props.isButtonActive}
              titleLines={props.titleLines}
              nextInnerStep={props.nextInnerStep}
              previousInnerStep={props.previousInnerStep}
              userScores={props.userScores}
              updateUserScores={props.updateUserScores}
              handleMultiResponses={props.handleSaveUserResponsesMulti}
              step={step}
              question={'Q2'}
              bonusQuestion={'BQ2'}
              isBonus={true}
              previousBonusQuestion={props.previousBonusQuestion}
              nextBonusQuestion={props.nextBonusQuestion}
              options={[
                {
                  responseText: 'Hormonal changes',
                  score: {
                    'Dry Skin': 0,
                    'Oily Skin': 1,
                    Acne: 1
                  },
                },
                {
                  responseText: 'Lack of sleep',
                  score: {
                    'Dry Skin': 0,
                    'Oily Skin': 1,
                    Acne: 1
                  },
                },
                {
                  responseText: 'Applying Cosmetic Products',
                  score: {
                    'Dry Skin': 0,
                    'Oily Skin': 0,
                    Acne: 0
                  },
                },
                {
                  responseText: 'Weather',
                  score: {
                    'Dry Skin': 1,
                    'Oily Skin': 0,
                    Acne: 1
                  },
                },
                {
                  responseText: 'Wearing a mask',
                  score: {
                    'Dry Skin': 1,
                    'Oily Skin': 0,
                    Acne: 1
                  },
                },
                {
                  responseText: 'Nutrition',
                  score: {
                    'Dry Skin': 0,
                    'Oily Skin': 1,
                    Acne: 1
                  },
                },
              ]}
              bonusCounter={props.bonusCounter}
              setBonusCounter={props.setBonusCounter} />

            <SingleChoiceQuestion
              getRecommendedIngredients={props.getRecommendedIngredients}
              topIngredientsNumber={3}
              questionTitle={"How does your skin usually react to skin care products for acne-prone skin?"}
              titleLines={2}
              nextInnerStep={props.nextInnerStep}
              previousInnerStep={props.previousInnerStep}
              userScores={props.userScores}
              updateUserScores={props.updateUserScores}
              updateUserResponses={props.updateUserResponses}
              step={step}
              question={'Q2'}
              isBonus={true}
              bonusQuestion={'BQ3'}
              nextBonusQuestion={props.nextBonusQuestion}
              returnFromBonusQuestion={props.returnFromBonusQuestion}
              previousBonusQuestion={props.previousBonusQuestion}
              isLastBonus={true}
              options={[
                {
                  responseText: 'It helps, and my skin feels comfortable',
                  score: {
                    'Oily Skin': 1,
                    'Dry Skin': 0,
                    Acne: 0
                  }
                },
                {
                  responseText: 'It works, but leaves my skin feeling dry and itchy',
                  score: {
                    'Oily Skin': 0,
                    'Dry Skin': 1,
                    Acne: 0
                  }
                },
                {
                  responseText: 'Usually useless, but my skin feels fine',
                  score: {
                    'Oily Skin': 0,
                    'Dry Skin': 0,
                    Acne: 1
                  }
                },
                {
                  responseText: 'Never tried'
                }
              ]}
              bonusCounter={props.bonusCounter}
              setBonusCounter={props.setBonusCounter}
            />
          </BonusQuestionsContainer>
        </BonusQuestion>

        {/*///////////////////////////////////QUESTION 3////////////////////////////////////////////*/}
        <BonusQuestion gap={'100px'} containerStyleY={props.containerStyleY} containerStyleBonus={props.containerStyleBonus}>
          <ImagesQuestion
            getRecommendedIngredients={props.getRecommendedIngredients}
            topIngredientsNumber={3}
            previousInnerStep={props.previousInnerStep}
            isOnStep={props.isOnStep}
            nextStep={props.nextStep}
            questionTitle={'Do you suffer from redness? Select all that apply:'}
            isButtonActive={props.isButtonActive}
            titleLines={1}
            nextInnerStep={props.nextInnerStep}
            isOnImgMultiSelectResponses={props.isOnImgMultiSelectResponses}
            activateBonusQuestions={props.activateRednessQuestions}
            displayBonusQuestions={props.displayRednessQuestions}
            updateUserScores={props.updateUserScores}
            userScores={props.userScores}
            question={'Q3'}
            handleMultiResponses={props.handleSaveUserResponsesMulti}
            step={step}
            options={[
              {
                responseText: 'Red skin',
                image: `${imagesUrl}/Red_skin.jpg`,
                score: { Rosacea: 1 },
                activateBonus: true
              },
              {
                responseText: 'Broken capillaries',
                image: `${imagesUrl}/Blood_vessels.jpg`,
                score: { Rosacea: 2 },
                isCapillaries: true,
              },
              {
                responseText: 'Rosacea',
                image: `${imagesUrl}/Rosacea.jpg`,
                score: { Rosacea: 3 },
              },
            ]}
          />
          <BonusQuestionsContainer
            width={(props.questionWidth * 1)}
            displayBonusQuestions={props.displayRednessQuestions}
          >
            <SliderQuestion
              getRecommendedIngredients={props.getRecommendedIngredients}
              topIngredientsNumber={3}
              questionTitle={"How often do you notice redness?"}
              sliderTextLeft="From time to time"
              sliderTextRight="Most of the time"
              sliderStyle={BlueYellowSliderStyles}
              texts={2}
              marks={marks}
              defaultSliderValue={50}
              nextInnerStep={props.nextInnerStep}
              previousInnerStep={props.previousInnerStep}
              userScores={props.userScores}
              updateUserScores={props.updateUserScores}
              updateUserResponses={props.updateUserResponses}
              returnFromBonusQuestion={props.returnFromBonusQuestion}
              nextBonusQuestion={props.nextBonusQuestion}
              bonusQuestion={'BQ1'}
              step={step}
              question={'Q3'}
              isBonus={true}
              isLastBonus={true}
              score={{
                0: { Rosacea: 0 },
                25: { Rosacea: 0 },
                50: { Rosacea: 1 },
                75: { Rosacea: 2 },
                100: { Rosacea: 3 }
              }}
              bonusCounter={props.bonusCounter}
              setBonusCounter={props.setBonusCounter} />
          </BonusQuestionsContainer>
        </BonusQuestion>

        {/*///////////////////////////////////QUESTION 4////////////////////////////////////////////*/}
        <ImagesQuestion
          getRecommendedIngredients={props.getRecommendedIngredients}
          topIngredientsNumber={3}
          previousInnerStep={props.previousInnerStep}
          isOnStep={props.isOnStep}
          nextStep={props.nextStep}
          questionTitle={'Do you suffer from dryness? Select all that apply:'}
          isButtonActive={props.isButtonActive}
          answerArray={props.responses.Step3.Q4.answer}
          titleLines={1}
          nextInnerStep={props.nextInnerStep}
          isOnImgMultiSelectResponses={props.isOnImgMultiSelectResponses}
          userScores={props.userScores}
          updateUserScores={props.updateUserScores}
          question={'Q4'}
          handleMultiResponses={props.handleSaveUserResponsesMulti}
          step={step}
          options={[
            {
              responseText: 'Skin flaking',
              image: `${imagesUrl}/Skin_flaking.jpg`,
              score: { Seborrhea: 5 },
            },
            {
              responseText: 'Dandruff',
              image: `${imagesUrl}/Dandruff.jpg`,
              score: { 'Oily Skin': 2 },
            },
            {
              responseText: 'Seborrhea',
              image: `${imagesUrl}/Seborrhea.jpg`,
              score: { Seborrhea: 10 },
            },
          ]}
        />
      </>
    );
  }
  if (width < breakpoint) {
    return (<>
      {
        <BonusQuestion containerStyleY={props.containerStyleY} isInvisible={!(props.isOnStep === 3 && props.step3InnerCount === 1)}>
          <ImagesQuestion
            previousInnerStep={props.previousInnerStep}
            getRecommendedIngredients={props.getRecommendedIngredients}
            topIngredientsNumber={3}
            questionTitle={'Select all of age-related options that apply:'}
            isButtonActive={props.isButtonActive}
            titleLines={1}
            nextInnerStep={props.nextInnerStep}
            isOnImgMultiSelectResponses={props.isOnImgMultiSelectResponses}
            activateBonusQuestions={props.activatePigmentationQuestions}
            displayBonusQuestions={props.displayPigmentationQuestions}
            updateUserScores={props.updateUserScores}
            userScores={props.userScores}
            question={'Q1'}
            handleMultiResponses={props.handleSaveUserResponsesMulti}
            step={step}
            options={[
              {
                responseText: 'Sagging skin',
                image: `${imagesUrl}/Sagging_skin.jpg`,
                score: { Aging: 1 },
              },
              {
                responseText: 'Fine lines',
                image: `${imagesUrl}/Fine_lines.jpg`,
                score: { Aging: 1 },
              },
              {
                responseText: 'Wrinkles',
                image: `${imagesUrl}/Wrinkles.jpg`,
                score: { Aging: 1 },
              },
              {
                responseText: 'Pigmentation',
                image: `${imagesUrl}/Pigmentation.jpg`,
                score: { Pigmentation: 1 },
                activateBonus: true
              },
            ]}
          />
          <BonusQuestionsContainer
            // containerStyle={props.containerStyle}
            // paddingLeft={'0'}
            // width={(props.questionWidth * 3)}
            displayBonusQuestions={props.displayPigmentationQuestions}
          >
            <SingleChoiceQuestion
              getRecommendedIngredients={props.getRecommendedIngredients}
              isInvisible={bonusQuestionOpen !== 1}
              topIngredientsNumber={3}
              questionTitle={"Which pigmentation problem do you suffer from?"}
              titleLines={2}
              nextInnerStep={props.nextInnerStep}
              previousInnerStep={props.previousInnerStep}
              userScores={props.userScores}
              updateUserScores={props.updateUserScores}
              updateUserResponses={props.updateUserResponses}
              step={step}
              question={'Q1'}
              isBonus={true}
              bonusQuestion={'BQ1'}
              nextBonusQuestion={nextBonusQuestion}
              previousBonusQuestion={prevBonusQuestion}
              returnFromBonusQuestion={props.returnFromBonusQuestion}
              options={[
                {
                  responseText: 'Discoloration',
                  icon: discoloration
                },
                {
                  responseText: 'Dark spots',
                  score: { Pigmentation: 2 },
                  icon: dark_spots
                }
              ]}
            />

            <SingleChoiceQuestion
              tip={selectQItems('BQ2')[0]}
              TipPopup={TipPopup}
              getRecommendedIngredients={props.getRecommendedIngredients}
              isInvisible={bonusQuestionOpen !== 2}
              topIngredientsNumber={3}
              questionTitle={"Are the pigmentation issues related to sun exposure?"}
              titleLines={2}
              nextInnerStep={props.nextInnerStep}
              previousInnerStep={props.previousInnerStep}
              userScores={props.userScores}
              updateUserScores={props.updateUserScores}
              updateUserResponses={props.updateUserResponses}
              step={step}
              question={'Q1'}
              isBonus={true}
              bonusQuestion={'BQ2'}
              nextBonusQuestion={nextBonusQuestion}
              previousBonusQuestion={prevBonusQuestion}
              options={[
                {
                  responseText: 'Yes',
                  score: { Photoaging: 2 }
                },
                {
                  responseText: 'No'
                },
                {
                  responseText: 'Not sure',
                  score: { Photoaging: 1 }
                }
              ]}
            />

            <MultiChoiceQuestion
              getRecommendedIngredients={props.getRecommendedIngredients}
              isInvisible={bonusQuestionOpen !== 3}
              resetBonusQuestions={resetBonusQuestions}
              topIngredientsNumber={3}
              width={'463px'}
              isBonus={true}
              questionTitle={"Which other factors seem to affect the appearance of the spots?"}
              isButtonActive={props.isButtonActive}
              titleLines={props.titleLines}
              nextInnerStep={props.nextInnerStep}
              previousInnerStep={props.previousInnerStep}
              userScores={props.userScores}
              updateUserScores={props.updateUserScores}
              handleMultiResponses={props.handleSaveUserResponsesMulti}
              step={step}
              question={'Q1'}
              bonusQuestion={'BQ3'}
              returnFromBonusQuestion={props.returnFromBonusQuestion}
              previousBonusQuestion={prevBonusQuestion}
              isLastBonus={true}
              options={[
                {
                  responseText: 'Hormonal changes',
                  score: {}
                },
                {
                  responseText: 'Stress, fatigue',
                  score: {},
                },
                {
                  responseText: 'Acne scars',
                  score: {}
                },
                {
                  responseText: 'Medical issues',
                  score: {}
                },
              ]} />
          </BonusQuestionsContainer>
        </BonusQuestion>

      }

      {
        <BonusQuestion containerStyleY={props.containerStyleY} isInvisible={!(props.isOnStep === 3 && props.step3InnerCount === 2)}>
        <ImagesQuestion
            getRecommendedIngredients={props.getRecommendedIngredients}
            topIngredientsNumber={3}
            previousInnerStep={props.previousInnerStep}
            isOnStep={props.isOnStep}
            nextStep={props.nextStep}
            questionTitle={'Do you suffer from acne? Select all that apply:'}
            isButtonActive={props.isButtonActive}
            titleLines={'test'}
            nextInnerStep={props.nextInnerStep}
            isOnImgMultiSelectResponses={props.isOnImgMultiSelectResponses}
            activateBonusQuestions={props.activateAcneQuestions}
            displayBonusQuestions={props.displayAcneQuestions}
            updateUserScores={props.updateUserScores}
            userScores={props.userScores}
            question={'Q2'}
            handleMultiResponses={props.handleSaveUserResponsesMulti}
            step={step}
            options={[
              {
                responseText: 'Blackheads',
                image: `${imagesUrl}/Blackheads.jpg`,
                score: { Acne: 1 },
              },
              {
                responseText: 'Breakouts',
                image: `${imagesUrl}/Pimples.jpg`,
                score: { Acne: 2 },
                activateBonus: true
              },
              {
                responseText: 'Severe acne',
                image: `${imagesUrl}/Severe_acne.jpg`,
                score: { Acne: 3 },
                activateBonus: true
              },
            ]}
          />
          <BonusQuestionsContainer
            displayBonusQuestions={props.displayAcneQuestions}
          >
            <SliderQuestion
              getRecommendedIngredients={props.getRecommendedIngredients}
              isInvisible={bonusQuestionOpen !== 1}
              topIngredientsNumber={3}
              questionTitle={"How often do you have breakouts?"}
              sliderTextLeft="Only on occasion"
              sliderTextRight="Always & constantly"
              sliderStyle={BlueYellowSliderStyles}
              texts={2}
              marks={marks}
              defaultSliderValue={50}
              nextInnerStep={props.nextInnerStep}
              previousInnerStep={props.previousInnerStep}
              userScores={props.userScores}
              updateUserScores={props.updateUserScores}
              updateUserResponses={props.updateUserResponses}
              returnFromBonusQuestion={props.returnFromBonusQuestion}
              nextBonusQuestion={nextBonusQuestion}
              bonusQuestion={'BQ1'}
              isBonus={true}
              step={step}
              question={'Q2'}
              score={{
                0: { Acne: 0 },
                25: { Acne: 0 },
                50: { Acne: 1 },
                75: { Acne: 2 },
                100: { Acne: 3 }
              }} />

            <MultiChoiceQuestion
              getRecommendedIngredients={props.getRecommendedIngredients}
              isInvisible={bonusQuestionOpen !== 2}
              topIngredientsNumber={3}
              width={'487px'}
              questionTitle={"What do you feel causes these changes?"}
              isButtonActive={props.isButtonActive}
              titleLines={props.titleLines}
              nextInnerStep={props.nextInnerStep}
              previousInnerStep={props.previousInnerStep}
              userScores={props.userScores}
              updateUserScores={props.updateUserScores}
              handleMultiResponses={props.handleSaveUserResponsesMulti}
              step={step}
              question={'Q2'}
              bonusQuestion={'BQ2'}
              isBonus={true}
              previousBonusQuestion={prevBonusQuestion}
              nextBonusQuestion={nextBonusQuestion}
              options={[
                {
                  responseText: 'Hormonal changes',
                  score: {
                    'Dry Skin': 0,
                    'Oily Skin': 1,
                    Acne: 1
                  },
                },
                {
                  responseText: 'Lack of sleep',
                  score: {
                    'Dry Skin': 0,
                    'Oily Skin': 1,
                    Acne: 1
                  },
                },
                {
                  responseText: 'Applying Cosmetic Products',
                  score: {
                    'Dry Skin': 0,
                    'Oily Skin': 0,
                    Acne: 0
                  },
                },
                {
                  responseText: 'Weather',
                  score: {
                    'Dry Skin': 1,
                    'Oily Skin': 0,
                    Acne: 1
                  },
                },
                {
                  responseText: 'Wearing a mask',
                  score: {
                    'Dry Skin': 1,
                    'Oily Skin': 0,
                    Acne: 1
                  },
                },
                {
                  responseText: 'Nutrition',
                  score: {
                    'Dry Skin': 0,
                    'Oily Skin': 1,
                    Acne: 1
                  },
                },
              ]} />

            <SingleChoiceQuestion
              getRecommendedIngredients={props.getRecommendedIngredients}
              isInvisible={bonusQuestionOpen !== 3}
              topIngredientsNumber={3}
              questionTitle={"How does your skin usually react to skin care products for acne-prone skin?"}
              titleLines={2}
              nextInnerStep={props.nextInnerStep}
              previousInnerStep={props.previousInnerStep}
              userScores={props.userScores}
              updateUserScores={props.updateUserScores}
              updateUserResponses={props.updateUserResponses}
              step={step}
              question={'Q2'}
              isBonus={true}
              bonusQuestion={'BQ3'}
              nextBonusQuestion={nextBonusQuestion}
              returnFromBonusQuestion={props.returnFromBonusQuestion}
              previousBonusQuestion={prevBonusQuestion}
              resetBonusQuestions={resetBonusQuestions}
              isLastBonus={true}
              options={[
                {
                  responseText: 'It helps, and my skin feels comfortable',
                  score: {
                    'Oily Skin': 1,
                    'Dry Skin': 0,
                    Acne: 0
                  }
                },
                {
                  responseText: 'It works, but leaves my skin feeling dry and itchy',
                  score: {
                    'Oily Skin': 0,
                    'Dry Skin': 1,
                    Acne: 0
                  }
                },
                {
                  responseText: 'Usually useless, but my skin feels fine',
                  score: {
                    'Oily Skin': 0,
                    'Dry Skin': 0,
                    Acne: 1
                  }
                },
                {
                  responseText: 'Never tried'
                }
              ]}
            />
          </BonusQuestionsContainer>
        </BonusQuestion>
      }

      {
        <BonusQuestion containerStyleY={props.containerStyleY} isInvisible={!(props.isOnStep === 3 && props.step3InnerCount === 3)}>
          <ImagesQuestion
            getRecommendedIngredients={props.getRecommendedIngredients}
            topIngredientsNumber={3}
            previousInnerStep={props.previousInnerStep}
            isOnStep={props.isOnStep}
            nextStep={props.nextStep}
            questionTitle={'Select all of redness-related options that apply:'}
            isButtonActive={props.isButtonActive}
            titleLines={1}
            nextInnerStep={props.nextInnerStep}
            isOnImgMultiSelectResponses={props.isOnImgMultiSelectResponses}
            activateBonusQuestions={props.activateRednessQuestions}
            displayBonusQuestions={props.displayRednessQuestions}
            updateUserScores={props.updateUserScores}
            userScores={props.userScores}
            question={'Q3'}
            handleMultiResponses={props.handleSaveUserResponsesMulti}
            step={step}
            options={[
              {
                responseText: 'Red skin',
                image: `${imagesUrl}/Red_skin.jpg`,
                score: { Rosacea: 1 },
                activateBonus: true
              },
              {
                responseText: 'Broken capillaries',
                image: `${imagesUrl}/Blood_vessels.jpg`,
                score: { Rosacea: 2 },
                isCapillaries: true,
              },
              {
                responseText: 'Rosacea',
                image: `${imagesUrl}/Rosacea.jpg`,
                score: { Rosacea: 3 },
              },
            ]}
          />
          <BonusQuestionsContainer
            width={(props.questionWidth * 1)}
            displayBonusQuestions={props.displayRednessQuestions}
          >
            <SliderQuestion
              getRecommendedIngredients={props.getRecommendedIngredients}
              topIngredientsNumber={3}
              questionTitle={"How often do you notice redness?"}
              sliderTextLeft="From time to time"
              sliderTextRight="Most of the time"
              sliderStyle={BlueYellowSliderStyles}
              texts={2}
              marks={marks}
              defaultSliderValue={50}
              nextInnerStep={props.nextInnerStep}
              previousInnerStep={props.previousInnerStep}
              userScores={props.userScores}
              updateUserScores={props.updateUserScores}
              updateUserResponses={props.updateUserResponses}
              returnFromBonusQuestion={props.returnFromBonusQuestion}
              nextBonusQuestion={props.nextBonusQuestion}
              bonusQuestion={'BQ1'}
              step={step}
              question={'Q3'}
              isBonus={true}
              isLastBonus={true}
              score={{
                0: { Rosacea: 0 },
                25: { Rosacea: 0 },
                50: { Rosacea: 1 },
                75: { Rosacea: 2 },
                100: { Rosacea: 3 }
              }} />
          </BonusQuestionsContainer>
        </BonusQuestion>
      }

      {
        <ImagesQuestion
          isInvisible={!(props.isOnStep === 3 && props.step3InnerCount === 4)}
          getRecommendedIngredients={props.getRecommendedIngredients}
          topIngredientsNumber={3}
          previousInnerStep={props.previousInnerStep}
          isOnStep={props.isOnStep}
          nextStep={props.nextStep}
          questionTitle={'Select all of flakes-related options that apply:'}
          isButtonActive={props.isButtonActive}
          answerArray={props.responses.Step3.Q4.answer}
          titleLines={1}
          nextInnerStep={props.nextInnerStep}
          isOnImgMultiSelectResponses={props.isOnImgMultiSelectResponses}
          userScores={props.userScores}
          updateUserScores={props.updateUserScores}
          question={'Q4'}
          handleMultiResponses={props.handleSaveUserResponsesMulti}
          step={step}
          options={[
            {
              responseText: 'Skin flaking',
              image: `${imagesUrl}/Skin_flaking.jpg`,
              score: { Seborrhea: 5 },
            },
            {
              responseText: 'Dandruff',
              image: `${imagesUrl}/Dandruff.jpg`,
              score: { 'Oily Skin': 2 },
            },
            {
              responseText: 'Seborrhea',
              image: `${imagesUrl}/Seborrhea.jpg`,
              score: { Seborrhea: 10 },
            },
          ]}
        />
      }
    </>
    )
  }
}
