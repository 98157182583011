import Button from '../Button/Button';
import { useState, useEffect } from 'react';

export default function Cart(props) {

    const [scrollingClass, setScrollingClass] = useState('');

    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 110) {
          setScrollingClass('cart-container_scrolling');
        } else {
          setScrollingClass('');
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    return(
        <div className={`cart-container ${scrollingClass}`}  onClick={(e) => {
            props.goToCheckout(e, props.cartUrl);
         }}> 
            <div className='cart-text'>Cart {Number(props.price).toFixed(2)} $</div>
            <div className='cart-logo'><div className='amount-text'>{props.itemCount}</div></div>
        </div>
    );
}