import StepCounter from '../StepCounter/StepCounter';

export default function StepCountersInactive(props) {
  if (props.isOnStep === 1) {
    return (
      <div className="main-steps-container main-steps-container_bottom">
        <StepCounter openedQuestions={props.openedQuestions} steps={5} stepCount={2} moveStep={props.moveStep} />
        <StepCounter openedQuestions={props.openedQuestions} steps={4} stepCount={3} moveStep={props.moveStep} />
        <StepCounter openedQuestions={props.openedQuestions} steps={5} stepCount={4} moveStep={props.moveStep} />
      </div>
    );
  }
  if (props.isOnStep === 2) {
    return (
      <div className="main-steps-container main-steps-container_bottom">
        <StepCounter openedQuestions={props.openedQuestions} steps={4} stepCount={3} moveStep={props.moveStep} />
        <StepCounter openedQuestions={props.openedQuestions} steps={5} stepCount={4} moveStep={props.moveStep} />
      </div>
    );
  }
  if (props.isOnStep === 3) {
    return (
      <div className="main-steps-container main-steps-container_bottom">
        <StepCounter openedQuestions={props.openedQuestions} steps={5} stepCount={4} moveStep={props.moveStep} />
      </div>
    );
  }
  if (props.isOnStep === 4) {
    return (
      <div className="main-steps-container main-steps-container_bottom">
      </div>
    );
  }
}
