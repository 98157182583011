import IntroInput from "../IntroInput/IntroInput";
import { useEffect, useState } from "react";

export default function EmailStep(props) {
  const bgImageUrl = 'https://aiodefilesbucket.s3.amazonaws.com/fre_bg/bg';
  const [bgNumber, setBgNumber] = useState(1);

  useEffect(() => {
    const randomNum = window.innerWidth > 768
      ? Math.floor(Math.random() * 6) + 1
      : Math.floor(Math.random() * 3) + 2;
    setBgNumber(randomNum);
  }, []);



  if (props.isOnStep === 4.5) return (
    <div className="email-step" style={{ backgroundImage: `url(${bgImageUrl}${bgNumber}.png)` }}>
      <div className="email-step-container">
        <h1 className="email-step-title">
          {props.isResultReady
            ? `Results are ready!`
            : `Preparing your results!`}
        </h1>
        <h3 className="email-step-subtitle">
          FRÉ is your active beauty partner for healthy, glowing skin.
        </h3>
        <IntroInput
          onClick={props.onClick}
          onChange={props.getEmail}
          isRequired={false}
          placeholder='Email to receive discount coupon'
          buttonText="get my results"
          isResultReady={props.isResultReady} />
      </div>
    </div>
  )
}