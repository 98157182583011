export default function IngredientImage(props) {
  return (
    <span className="ingredient-image-container" style={{ width: props.width, height: props.height }}>
      <img
        className={(props.border && props.name !== 'Argania Active Complex')
          ? 'ingredient-image ingredient-image_bordered'
          : 'ingredient-image'}
        src={props.src}
        alt={props.name}
        style={{ width: props.width, height: props.height }}
      />
      {(props.name === 'Argania Active Complex' && props.isOverlay)
        && <span className={props.overlaySmall
          ? 'ingredient-image-overlay ingredient-image-overlay_small'
          : 'ingredient-image-overlay'} style={{ fontSize: props.fontSize }}>Unique patent</span>}
    </span>
  )
}