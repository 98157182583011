import StepCounter from '../StepCounter/StepCounter';

export default function StepsCounters(props) {
  if (props.isOnStep === 1) {
    return (
      <div className="main-steps-container">
        <StepCounter
          openedQuestions={props.openedQuestions}
          steps={3}
          stepCount={1}
          moveStep={props.moveStep}
          isOnStep={props.isOnStep}
          innerStepCount={props.step1InnerCount}
        />
        <p className="step-counter-text">
          Let's start with getting to know you.
        </p>
      </div>
    );
  }
  if (props.isOnStep === 2) {
    return (
      <div className="main-steps-container">
        <StepCounter
          openedQuestions={props.openedQuestions}
          steps={3}
          moveStep={props.moveStep}
          stepCount={1}
          isOnStep={props.isOnStep}
          innerStepCount={props.step1InnerCount}
        />
        <StepCounter
          openedQuestions={props.openedQuestions}
          steps={5}
          moveStep={props.moveStep}
          stepCount={2}
          isOnStep={props.isOnStep}
          innerStepCount={props.step2InnerCount}
        />
        <p className="step-counter-text">
          Please tell us a little about your skin and we will help choose the
          best skin care regimen for you.
        </p>
      </div>
    );
  }
  if (props.isOnStep === 3) {
    return (
      <div className="main-steps-container">
        <StepCounter
          openedQuestions={props.openedQuestions}
          steps={3}
          moveStep={props.moveStep}
          stepCount={1}
          isOnStep={props.isOnStep}
          innerStepCount={props.step1InnerCount}
        />
        <StepCounter
          openedQuestions={props.openedQuestions}
          steps={5}
          moveStep={props.moveStep}
          stepCount={2}
          isOnStep={props.isOnStep}
          innerStepCount={props.step2InnerCount}
        />
        <StepCounter
          openedQuestions={props.openedQuestions}
          steps={4}
          moveStep={props.moveStep}
          stepCount={3}
          isOnStep={props.isOnStep}
          innerStepCount={props.step3InnerCount}
        />
        <p className="step-counter-text">
          Let's be even more specific about your skin.
        </p>
      </div>
    );
  }
  if (props.isOnStep === 4) {
    return (
      <div className="main-steps-container">
        <StepCounter
          openedQuestions={props.openedQuestions}
          steps={3}
          moveStep={props.moveStep}
          stepCount={1}
          isOnStep={props.isOnStep}
          innerStepCount={props.step1InnerCount}
        />
        <StepCounter
          openedQuestions={props.openedQuestions}
          steps={5}
          moveStep={props.moveStep}
          stepCount={2}
          isOnStep={props.isOnStep}
          innerStepCount={props.step2InnerCount}
        />
        <StepCounter
          openedQuestions={props.openedQuestions}
          steps={4}
          moveStep={props.moveStep}
          stepCount={3}
          isOnStep={props.isOnStep}
          innerStepCount={props.step3InnerCount}
        />
        <StepCounter
          openedQuestions={props.openedQuestions}
          steps={5}
          moveStep={props.moveStep}
          stepCount={4}
          isOnStep={props.isOnStep}
          innerStepCount={props.step4InnerCount}
        />
        <p className="step-counter-text">
          Several questions about your everyday habits.
        </p>
      </div>
    );
  }
}
