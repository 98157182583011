import TopIngredients from "../components/TopIngredients/TopIngredients";
import ScoreCalculatedTopIngredients from "../components/TopIngredients/ScoreCalculatedTopIngredients";

const renderTopIngredients = (props) => {
    return props.isCalculatedTopIngredients ? (
      <ScoreCalculatedTopIngredients
        isTopIngrediantsOn={props.isTopIngrediantsOn}
        recommendedIngredients={props.recommendedIngredients}
        isOnStep={props.isOnStep}
        //getRecommendedIngredients={props.getRecommendedIngredients}
      />
    ) : (
      <TopIngredients isTopIngrediantsOn={props.isTopIngrediantsOn} recommendedIngredients={props.recommendedIngredients}  />
    );
  };

  export default renderTopIngredients;