import ColorPicker from "../ColorPicker/ColorPicker";
import MultiChoiceQuestion from "../MultiChoiceQuestion/MultiChoiceQuestion";
import useScreenSize from "../../utils/useScreenSize";
import SingleChoiceQuestion from '../SingleChoiceQuestion/SingleChoiceQuestion';
import SliderQuestion from "../SliderQuestion/SliderQuestion";
import {
  marks,
  BlueSliderStyles,
  BlueYellowSliderStyles,
} from "../../utils/constants";
import TipPopup from "../TipPopup/TipPopup";

export default function Step2(props) {
  const { AllQtipsFunc } =props;
  const [width, breakpoint] = useScreenSize();

  const step = "Step2"

  function selectQItems(questionKey) {
    const selectedItems = AllQtipsFunc.filter(item =>
      item.question === questionKey 
    );
    return selectedItems;
  }

  if (width >= breakpoint) {
    return (
      <>
        <SliderQuestion
          getRecommendedIngredients={props.getRecommendedIngredients}
          topIngredientsNumber={2}
          questionTitle={"How does your skin react to sun?"}
          sliderTextLeft="My skin burns easily and hardly tans"
          sliderTextRight="My skin hardly gets sunburned"
          sliderStyle={BlueSliderStyles}
          texts={2}
          marks={marks}
          defaultSliderValue={50}
          nextInnerStep={props.nextInnerStep}
          previousInnerStep={props.previousInnerStep}
          userScores={props.userScores}
          updateUserScores={props.updateUserScores}
          updateUserResponses={props.updateUserResponses}
          step={step}
          question={'Q1'}
          score={{
            0: { Photoaging: 3 },
            25: { Photoaging: 2 },
            50: { Photoaging: 1 },
            75: { Photoaging: 0 },
            100: { Photoaging: 0 }
          }} />

        <SliderQuestion
          tip={selectQItems('Q2')[0]}
          TipPopup={TipPopup}
          getRecommendedIngredients={props.getRecommendedIngredients}
          topIngredientsNumber={3}
          questionTitle={"How does your skin feel after cleansing and before moisturizing?"}
          titleLines={props.titleLines}
          sliderTextLeft="Tight & dry"
          sliderTextCenter="Comfortable"
          sliderTextRight="Still greasy & shiny"
          sliderStyle={BlueYellowSliderStyles}
          texts={3}
          marks={marks}
          defaultSliderValue={50}
          nextInnerStep={props.nextInnerStep}
          previousInnerStep={props.previousInnerStep}
          userScores={props.userScores}
          updateUserScores={props.updateUserScores}
          updateUserResponses={props.updateUserResponses}
          step={step}
          question={'Q2'}
          score={{
            0: { 'Dry Skin': 2, 'Oily Skin': 0 },
            25: { 'Dry Skin': 1, 'Oily Skin': 0 },
            50: { 'Dry Skin': 0, 'Oily Skin': 0 },
            75: { 'Dry Skin': 0, 'Oily Skin': 1 },
            100: { 'Dry Skin': 0, 'Oily Skin': 2 }
          }} />

        <SingleChoiceQuestion
          getRecommendedIngredients={props.getRecommendedIngredients}
          topIngredientsNumber={3}
          questionTitle={"Does your skin usually feel drier in the winter?"}
          titleLines={props.titleLines}
          nextInnerStep={props.nextInnerStep}
          previousInnerStep={props.previousInnerStep}
          userScores={props.userScores}
          updateUserScores={props.updateUserScores}
          updateUserResponses={props.updateUserResponses}
          step={step}
          question={'Q3'}
          options={[
            {
              responseText: 'Yes',
              score: { 'Dry Skin': 2 }
            },
            {
              responseText: 'No',
            },
            {
              responseText: 'Not sure',
              score: { 'Dry Skin': 1 }
            }
          ]}
        />

        <ColorPicker
          getRecommendedIngredients={props.getRecommendedIngredients}
          topIngredientsNumber={3}
          updateUserScores={props.updateUserScores}
          userScores={props.userScores}
          updateUserResponses={props.updateUserResponses}
          nextInnerStep={props.nextInnerStep}
          step={step}
          question={'Q4'}
          previousStep={props.previousStep}
          questionTitle={"What is your skin tone?"}
          previousInnerStep={props.previousInnerStep}
          isButtonActive={true} />

        <MultiChoiceQuestion
          getRecommendedIngredients={props.getRecommendedIngredients}
          topIngredientsNumber={3}
          width={'604px'}
          questionTitle={"Is your skin sensitive? Select all that apply:"}
          isButtonActive={props.isButtonActive}
          titleLines={1}
          nextInnerStep={props.nextInnerStep}
          previousInnerStep={props.previousInnerStep}
          userScores={props.userScores}
          updateUserScores={props.updateUserScores}
          handleMultiResponses={props.handleSaveUserResponsesMulti}
          step={step}
          question={'Q5'}
          options={[
            {
              responseText: 'My skin is prone to allergic reactions',
              score: { 'Sensitive Skin': 1 },
            },
            {
              responseText: 'I have psoriasis',
              score: {
                'Sensitive Skin': 1,
                Psoriasis: 10
              },
            },
            {
              responseText: 'I have irritated skin',
              score: { 'Sensitive Skin': 1 },
            },
            {
              responseText: 'I have atopic dermatitis',
              score: {
                'Sensitive Skin': 1,
                'Atopic Dermatitis/ Eczema': 10
              },
            },
          ]} />
      </>
    );
  }
  if (width < breakpoint) {
    return (<>
      <SliderQuestion
        isInvisible={!(props.isOnStep === 2 && props.step2InnerCount === 1)}
        getRecommendedIngredients={props.getRecommendedIngredients}
        topIngredientsNumber={2}
        questionTitle={"How does your skin react to sun?"}
        sliderTextLeft="My skin burns easily and hardly tans"
        sliderTextRight="My skin hardly gets sunburned"
        sliderStyle={BlueSliderStyles}
        texts={2}
        marks={marks}
        defaultSliderValue={50}
        nextInnerStep={props.nextInnerStep}
        previousInnerStep={props.previousInnerStep}
        userScores={props.userScores}
        updateUserScores={props.updateUserScores}
        updateUserResponses={props.updateUserResponses}
        step={step}
        question={'Q1'}
        score={{
          0: { Photoaging: 3 },
          25: { Photoaging: 2 },
          50: { Photoaging: 1 },
          75: { Photoaging: 0 },
          100: { Photoaging: 0 }
        }} />
      <SliderQuestion
        tip={selectQItems('Q2')[0]}
        TipPopup={TipPopup}
        isInvisible={!(props.isOnStep === 2 && props.step2InnerCount === 2)}
        getRecommendedIngredients={props.getRecommendedIngredients}
        topIngredientsNumber={3}
        questionTitle={"How does your skin feel after cleaning and before moisturizing?"}
        titleLines={props.titleLines}
        sliderTextLeft="Tight & dry"
        sliderTextCenter="Comfortable"
        sliderTextRight="Still greasy & shiny"
        sliderStyle={BlueYellowSliderStyles}
        texts={3}
        marks={marks}
        defaultSliderValue={50}
        nextInnerStep={props.nextInnerStep}
        previousInnerStep={props.previousInnerStep}
        userScores={props.userScores}
        updateUserScores={props.updateUserScores}
        updateUserResponses={props.updateUserResponses}
        step={step}
        question={'Q2'}
        score={{
          0: { 'Dry Skin': 2, 'Oily Skin': 0 },
          25: { 'Dry Skin': 1, 'Oily Skin': 0 },
          50: { 'Dry Skin': 0, 'Oily Skin': 0 },
          75: { 'Dry Skin': 0, 'Oily Skin': 1 },
          100: { 'Dry Skin': 0, 'Oily Skin': 2 }
        }} />
      <SingleChoiceQuestion
        isInvisible={!(props.isOnStep === 2 && props.step2InnerCount === 3)}
        getRecommendedIngredients={props.getRecommendedIngredients}
        topIngredientsNumber={3}
        questionTitle={"Does your skin usually feel drier in the winter?"}
        titleLines={props.titleLines}
        nextInnerStep={props.nextInnerStep}
        previousInnerStep={props.previousInnerStep}
        userScores={props.userScores}
        updateUserScores={props.updateUserScores}
        updateUserResponses={props.updateUserResponses}
        step={step}
        question={'Q3'}
        options={[
          {
            responseText: 'Yes',
            score: { 'Dry Skin': 2 }
          },
          {
            responseText: 'No',
          },
          {
            responseText: 'Not sure',
            score: { 'Dry Skin': 1 }
          }
        ]}
      />

      <ColorPicker
        isInvisible={!(props.isOnStep === 2 && props.step2InnerCount === 4)}
        getRecommendedIngredients={props.getRecommendedIngredients}
        topIngredientsNumber={3}
        updateUserScores={props.updateUserScores}
        userScores={props.userScores}
        updateUserResponses={props.updateUserResponses}
        nextInnerStep={props.nextInnerStep}
        step={step}
        question={'Q4'}
        previousStep={props.previousStep}
        questionTitle={"Which Skin tone do you have?"}
        previousInnerStep={props.previousInnerStep}
        isButtonActive={false} />

      <MultiChoiceQuestion
        isInvisible={!(props.isOnStep === 2 && props.step2InnerCount === 5)}
        getRecommendedIngredients={props.getRecommendedIngredients}
        topIngredientsNumber={3}
        width={'604px'}
        questionTitle={"Is your skin sensitive? Select all that apply:"}
        isButtonActive={props.isButtonActive}
        titleLines={props.titleLines}
        nextInnerStep={props.nextInnerStep}
        previousInnerStep={props.previousInnerStep}
        userScores={props.userScores}
        updateUserScores={props.updateUserScores}
        handleMultiResponses={props.handleSaveUserResponsesMulti}
        step={step}
        question={'Q5'}
        options={[
          {
            responseText: 'My skin is prone to allergic reactions',
            score: { 'Sensitive Skin': 1 },
          },
          {
            responseText: 'I have psoriasis',
            score: {
              'Sensitive Skin': 1,
              Psoriasis: 10
            },
          },
          {
            responseText: 'I have irritated skin',
            score: { 'Sensitive Skin': 1 },
          },
          {
            responseText: 'I have atopic dermatitis',
            score: {
              'Sensitive Skin': 1,
              'Atopic Dermatitis/ Eczema': 10
            },
          },
        ]} />
    </>
    );
  }
}
