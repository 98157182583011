export default function BonusQuestionsContainer(props) {


  return (
    <div
      className={
        props.displayBonusQuestions === true
          ? 'bonus-questions-container'
          : 'bonus-questions-container bonus-questions-container_disabled'
      }
      style={{width: props.width, paddingLeft: props.paddingLeft}}
    >
      {props.children}
    </div>
  );
}
