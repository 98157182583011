import IngredientImage from "../IngredientImage/IngredientImage";
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import { EffectCoverflow, Pagination } from 'swiper/modules';

const ImgUrl = `https://aiodefilesbucket.s3.amazonaws.com/ingimages/{id}.jpg`

export default function IngredientCarousel(props) {

  return (
    <div className="ingredient-carousel">
        <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={'3'}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: false,
                }}
                initialSlide='1'
                pagination={true}
                modules={[EffectCoverflow, Pagination]}
        className="swiper_container">
            {props.recommendedIngredients.map((ingredient) => (
                <SwiperSlide key={ingredient.id}>
                    <IngredientImage
                        width='100%'
                        height='100%'
                        src={ImgUrl.replace('{id}', ingredient.id)}
                        name={ingredient.name}
                        isOverlay={true}
                        border={true}
                        fontSize={window.innerWidth > 630 ? '20px' : (window.innerWidth > 500 ? '18px' :'11px')}
                        overlaySmall={window.innerWidth < 480 ? true : false} />
                    <div className="ingredient-carousel-text">
                        <h4 className="ingredient-carousel-title">{ingredient.name}</h4>
                        <p className="ingredient-carousel-content">{ingredient.description}</p>
                    </div>
            
                </SwiperSlide>
            ))}
        </Swiper>
    </div>
  );
}