export default function CounterBlock(props) {

  const handleClick = () => {
    props.moveStep(props.stepCount, props.innerBlockOrder);
  };

  return (
    <div
      onClick={handleClick}
      className={  
        props.openedQuestions[props.stepCount][props.innerBlockOrder]      
        // props.innerStepCount >= props.innerBlockOrder
          ? 'step-counter-progress-bar step-counter-progress-bar_active'
          : 'step-counter-progress-bar'
      }
    ></div>
  );
}
