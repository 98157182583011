import axios from "axios";


axios.defaults.baseURL = "http://localhost:3001";

const fetchIngredientsData = async () => {
  try {
    const response = await axios.get("/ingredients");
    //console.log("Ingredients", response);
    return response.data;
  } catch (err) {
    console.log("error ingredients", err);
  }
};



export const ingredientService = {
  fetchIngredientsData,
};


