import { useEffect, useState, useRef } from 'react';

export default function MultiChoiceQuestion(props) {
  const [answers, setAnswers] = useState([]);
  const [activeBonus, setActiveBonus] = useState(false);
  const buttonTextRef = useRef(null);

  function handleCheckboxChange(e) {
    let newScores = [];
    const response = props.options.find(option => option.responseText === e.target.value);

    if (answers.includes(e.target.value)) {
      const newAnswers = answers.filter(item => item !== e.target.value);
      setAnswers(newAnswers);
      for (let key in response.score) {
        newScores[key] = props.userScores[key] - response.score[key];
        props.updateUserScores(newScores);
      }
      if (props.isBonus) {
        props.handleMultiResponses(props.step, e.target.value, 'remove', props.question, props.bonusQuestion);
      } else {
        props.handleMultiResponses(props.step, e.target.value, 'remove', props.question);
      }
      // response.updateCategory(false);
    } else {
      setAnswers((prev) => [...prev, e.target.value]);
      for (let key in response.score) {
        newScores[key] = props.userScores[key] + response.score[key];
        props.updateUserScores(newScores);
      }
      if (props.isBonus) {
        props.handleMultiResponses(props.step, e.target.value, 'push', props.question, props.bonusQuestion);
      } else {
        props.handleMultiResponses(props.step, e.target.value, 'push', props.question);
      }
      // response.updateCategory(true);
    }

    if (response.activateBonus) setActiveBonus(!activeBonus);
    // props.getRecommendedIngredients(props.topIngredientsNumber);
  };

  function handleSubmit(e) {
    e.preventDefault();
    if (props.isBonus) {
      if (props.isLastBonus) {
        props.returnFromBonusQuestion(true);
        if (props.setBonusCounter) props.setBonusCounter(0);
        if(props.resetBonusQuestions) {props.resetBonusQuestions();}
      } else {
        props.nextBonusQuestion();
        if (props.setBonusCounter) props.setBonusCounter(props.bonusCounter + 1);
      }
    } else {
      props.nextInnerStep();
    }
  }

  function handleBackClick() {
    if (props.isBonus) {
      if (props.bonusQuestion === 'BQ1') {
        props.returnFromBonusQuestion(false);
        props.setBonusCounter(0);
        if(props.resetBonusQuestions) {props.resetBonusQuestions();}
      } else {
        props.previousBonusQuestion();
        props.setBonusCounter(props.bonusCounter - 1);
      }
    } else {
      props.previousInnerStep();
    }
  }

  useEffect(() => {
    if (answers.length === 0) {
      buttonTextRef.current.innerText = "none";
    } else {
      buttonTextRef.current.innerText = "next";
    }
  }, [answers]);

  return (
    <div className="question-container" style={{display: props.isInvisible && "none"}}>
      <div className='question'>
        <div
          className={
            props.titleLines === 2
              ? 'question-title question-title_two-line'
              : 'question-title'
          }>
          {props.questionTitle}
        </div>
        <form className='multi-choice-question-form' onSubmit={handleSubmit} style={{ width: props.width }}>
          <div className='multi-choice-question-question-content'>
            {props.options.map((option, index) => (
              <label key={index} className='multi-choice-question-label'>
                <div className='multi-choice-question-response-container'>
                  <input
                    className='multi-choice-question-input'
                    type='checkbox'
                    name={option.responseText}
                    value={option.responseText}
                    onChange={handleCheckboxChange} />
                  <span className='multi-choice-question-checkbox' />
                  <p className='multi-choice-question-response'>{option.responseText}</p>
                </div>
              </label>
            ))}
          </div>
          <div className="question-button-container">
            <button
              className="question-back-button"
              type='button'
              onClick={handleBackClick} />
            <button
              className={props.isButtonActive ? 'button' : 'button_inactive'}
              type='submit'
              ref={buttonTextRef} />
          </div>
        </form>
      </div>
    </div>
  )
}