export default function LoadingSpinner() {
  const circleStyles = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <div className="loading-spinner">
      {circleStyles.map((i) => (
        <span className="loading-spinner-circle" style={{'--i': i}} key={i}></span>
      ))}
    </div>
  )
}