export default function Button(props) {
  if (props.isOnRecommendedProduct) {
    return (
      <button
        className={`
          ${props.isButtonActive ? "button button_add-to-cart" : "button_inactive"}
          ${props.isYellow && 'button_yellow'}`
        }
        onClick={props.onClick}
      >
        {props.buttonText}
      </button>
    );
  }

  if (props.isOnStep === 5) {
    return (
      <button
        className={
          props.isButtonActive
            ? `button button_recommended-set ${props.isExtendRecommend ? "button_recommended-set_extend" : ""
            }`
            : "button_inactive"
        }
        onClick={props.onClick}
      >
        {props.buttonText}
      </button>
    );
  }
  if (props.isOnStep === 0 || 4.5) {
    return (
      <button
        className={
          props.isButtonActive
            ? props.isYellow
            && "button button_intro button_yellow"
            : "button_inactive"
        }
        onClick={props.onClick}
      >
        {props.buttonText}
      </button>
    );
  }

  if (props.isOnStep === 1 || 2 || 2.5 || 3) {
    return (
      <button
        className={props.isButtonActive ? "button" : "button_inactive"}
        onClick={props.onClick}
      >
        {props.buttonText}
      </button>
    );
  }
}
