import { useState, useEffect } from "react";

export default function InputQuestion(props) {
  const [isNameFilled, setIsNameFilled] = useState(true);
  const [name, setName] = useState('');

  function handleSubmit(e) {
    e.preventDefault();
    if (name.length > 0) {
      setIsNameFilled(true);
      props.nextInnerStep();
    } else {
      setIsNameFilled(false);
    }
  }

  function handleChangeInput(e) {
    setName(e.target.value);
    props.onInputChange(e);
  }

  useEffect(() => {
    console.log(isNameFilled)
  }, [isNameFilled, name]);

  return (
    <div className="question-container" style={{display: props.isInvisible && "none"}}>
      <div
        className={
          props.isOnImgMultiSelectResponses
            ? 'question question_no-margin'
            : 'question'
        }
      >
        <div
          className={
            props.titleLines === 2
              ? 'question-title question-title_two-line'
              : 'question-title'
          }
        >
          {props.questionTitle}
        </div>
        <form
          className="input-question"
          onSubmit={handleSubmit}
        >
          <div className="input-question-input-box">
            <input
              className={
                props.isIntro
                  ? 'input-question-box input-question-box_intro'
                  : 'input-question-box'
              }
              placeholder={props.placeholder}
              // value={props.name}
              maxLength={props.length[1]}
              onChange={handleChangeInput}
              // onChange={props.onInputChange}
              // required={props.isRequired}
              autoFocus />
              {!isNameFilled && (<span className="input-box_error">Please enter your name</span>)}
            {/* <span className={
              isNameFilled ?
                `input-box_error`
                : `input-box_error input-box_error_visible`}>Please enter your name</span> */}
          </div>

          <div className="question-button-container">
            <button
              className="question-back-button"
              type='button'
              onClick={props.previousInnerStep} />
            <button
              className='button'
              type='submit'>{props.buttonText}</button>
          </div>
        </form>
      </div>
    </div>
  )
}