import { motion } from 'framer-motion';
import Input from '../Input/Input';
import Button from '../Button/Button';
import InputQuestion from '../InputQuestion/InputQuestion';
import IntroInput from '../IntroInput/IntroInput';

export default function Introduction(props) {
  const innerStepReset = () => {
    props.nextStep();
    props.setStep1InnerCount(1);
  };

  if (props.isOnStep === 0) {
    return (
      <div className="intro">
        <motion.div
          initial={{ opacity: 0, y: 1000 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          exit={{ opacity: 0 }}
          className="intro-container"
        >
          <button
            className="intro-close-btn"
            onClick={props.closeTheQuiz}
          ></button>
          <div className="intro-water">
            <h1 className="intro-title">DISCOVER<br></br>YOUR BEST SKIN SET</h1>
            <p className="intro-subtitle">
            FRÉ’s AI finds the products you need by matching our high-performance ingredients to your skin.
            </p>
            <p className="intro-subtitle intro-subtitle-highlited">
            Take our 3-minute quiz.
            </p>
            {/* <IntroInput
              placeholder={'Email to recieve discount coupon'}
              buttonText={'Start the quiz'}
              isRequired={false}
              onClick={innerStepReset}
              onChange={props.getEmail} />
            {/* <Input
              isOnIntro={props.isOnIntro}
              isOnStep={props.isOnStep}
              placeholder={'Email to recieve discount coupon'}
            /> */}
            <Button
              isButtonActive={props.isButtonActive}
              buttonText={'LET’S GLOW!'}
              isOnIntro={props.isOnIntro}
              isOnStep={props.isOnStep}
              onClick={innerStepReset}
              isYellow={true}
            />
          </div>
        </motion.div>
      </div>
    );
  }
}
