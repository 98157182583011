import IngredientImage from "../IngredientImage/IngredientImage";

export default function IngredientInfo(props) {
  return (
    <div className="ingredient-info">
      <IngredientImage
        width={window.innerWidth < 1200 ? '100%' : '278px'}
        height={window.innerWidth < 1200 ? '100%' : '278px'}
        src={props.ingredientImage}
        name={props.name}
        isOverlay={true}
        border={true} />
      {/* <img
        className="ingredient-info-img"
        src={props.ingredientImage}
        alt={props.alt}
      /> */}
      <h4 className="ingredient-info-title">{props.ingredientTitle}</h4>
      <p className="ingredient-info-content">{props.paragraph}</p>
    </div>
  );
}
