import './StepCountersMobile.css'
import StepCounter from '../StepCounter/StepCounter';

const StepCountersMobile = (props) => {
    const handleClick = (evt) => {
        const step = evt.target.dataset.step*1;
        props.moveStep(step, 1);     
    }
    return(
        <div className="step-counters-mob">
            <div className="step-counters-mob__titles">
              <div data-step={1} onClick={handleClick} className={`${props.isOnStep === 1 ? "step-counter-mob__step-title step-counter-mob__step-title_active" : "step-counter-mob__step-title"}`}>step 1</div>
              <div data-step={2} onClick={handleClick} className={`${props.isOnStep === 2 ? "step-counter-mob__step-title step-counter-mob__step-title_active" : "step-counter-mob__step-title"}`}>step 2</div>
              <div data-step={3} onClick={handleClick} className={`${props.isOnStep === 3 ? "step-counter-mob__step-title step-counter-mob__step-title_active" : "step-counter-mob__step-title"}`}>step 3</div>
              <div data-step={4} onClick={handleClick} className={`${props.isOnStep === 4 ? "step-counter-mob__step-title step-counter-mob__step-title_active" : "step-counter-mob__step-title"}`}>step 4</div>
            </div>
            {
                props.isOnStep === 1 &&
                <div className="steps-container">
                 <StepCounter
                  openedQuestions={props.openedQuestions}
                   steps={3}
                   stepCount={1}
                   moveStep={props.moveStep}
                   isOnStep={props.isOnStep}
                   innerStepCount={props.step1InnerCount}
                 />
                  <div className="step-counter-text">
                  Let's start with getting to know you.
                  </div>
                </div>
            }
            {

                props.isOnStep === 2 &&
                <div className="steps-container">
                 <StepCounter
                  openedQuestions={props.openedQuestions}
                   steps={4}
                   stepCount={2}
                   moveStep={props.moveStep}
                   isOnStep={props.isOnStep}
                   innerStepCount={props.step2InnerCount}
                 />
                  <div className="step-counter-text">
                  Please tell us a little about your skin and we will help choose the
                  best skin care regimen for you.
                  </div>
                </div>
            }
            {
                props.isOnStep === 3 &&
                <div className="steps-container">
                 <StepCounter
                  openedQuestions={props.openedQuestions}
                   steps={4}
                   stepCount={3}
                   moveStep={props.moveStep}
                   isOnStep={props.isOnStep}
                   innerStepCount={props.step3InnerCount}
                 />
                  <div className="step-counter-text">
                  Let's be even more specific about your skin.
                  </div>
                </div>
            }
            {
                props.isOnStep === 4 &&
                <div className="steps-container">
                 <StepCounter
                  openedQuestions={props.openedQuestions}
                   steps={5}
                   stepCount={4}
                   moveStep={props.moveStep}
                   isOnStep={props.isOnStep}
                   innerStepCount={props.step4InnerCount}
                 />
                  <div className="step-counter-text">
                  Several questions about your everyday habits.
                  </div>
                </div>
            }
        </div>
    )

};

export default StepCountersMobile;