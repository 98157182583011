
import { useState } from 'react';
const TipPopup = (props) => {
    const [tipIsOpen, setTipIsOpen] = useState(false);
    const { tip } = props;

    function splitText() {
        let text = tip.text.split('\n');

        return <div className='tip-text'>
            {text.map((paragraph) =>
                <p className='tip-paragraph'>{paragraph}</p>)}
        </div>
    }

    function tipClicked() {
        if (tip)
            setTipIsOpen(!tipIsOpen);
    }
    return (
        <section className='tip'>
            <div onMouseEnter={() => tipClicked()} onMouseLeave={() => tipClicked()} onClick={() => tipClicked()} className={tipIsOpen ? 'tip-container-opend' : 'tip-container'}>
            </div>
            {tipIsOpen
                ? <div className='tip-container__open-text'>
                    <h3 className='tip-title'>{tip.title}</h3>
                    {splitText()}
                    {/* <p className='tip-text'></p> */}
                    <div class="triangle"></div>
                </div>
                : ""}
        </section>
    )

}
export default TipPopup