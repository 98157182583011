import { useState } from "react";
import IngredientsSmall from "../IngredientsSmall/IngredientsSmall";
import {
  buildCartLinkSingleItem,
  calculateDiscount,
} from "../../utils/CartUtils";
import Button from "../Button/Button";

export default function RecommendedProduct(props) {
  //states
  const [buttonText, setButtonText] = useState(
    `${props.price} $ | ADD TO CART`
  );
  const [isButtonYellow, setIsButtonYellow] = useState(false);

  const handleClick = () => {
    props.setCartUrl(buildCartLinkSingleItem(props.cartID, props.cartUrl));
    props.setItemCount((prevCount) => prevCount + 1);
    if (props.price) {
      props.setPrice(
        (prevPrice) =>
          prevPrice +
          props.price -
          calculateDiscount(props.price, props.discount)
      );
    }
    setButtonText("ADDED TO CART");
    setIsButtonYellow(true);
  };

  let personalSentenceComponents = [];
  if (props.personalSentences !== undefined) {
    personalSentenceComponents = props.personalSentences.map(
      (sentence, index) => (
        <li
          key={index}
          className="recommendend-product-small-text recommendend-product-small-text_no-padding recommendend-product-product-tag recommendend-product-product-tag_yellow"
        >
          {sentence}
        </li>
      )
    );
  }

  let bestMatchSentencesComponents = [];

  if (
    props.bestMatchSentences !== undefined &&
    props.bestMatchSentences.length > 0
  ) {
    bestMatchSentencesComponents = props.bestMatchSentences.map(
      (sentence, index) => (
        <>
          {sentence !== null && (
            <li
              key={index}
              className="recommendend-product-small-text recommendend-product-small-text_no-padding recommendend-product-product-tag recommendend-product-product-tag_blue"
            >
              {sentence}
            </li>
          )}
        </>
      )
    );
  }

  const [isOnRecommendedProduct, setIsOnRecommendedProduct] = useState(true);

  //function
  const stepNameAndType = () => {
    //
    const stepNumAndType = props.stepTitle.split(" — ");
    console.log("gali", stepNumAndType);
    return (
      <div className="recommended-product__step-title">
        <p className="recommended-product__step-title_num">{`${stepNumAndType[0]} —  `}</p>{" "}
        <p className="recommended-product__step-title_type">
          {stepNumAndType[1]}
        </p>
      </div>
    );
  };

  //render
  return (
    <div className="recommended-product">
      {stepNameAndType()}
      <div className="recommended-product-img-container">
        <h2 className="recommended-product-title">{props.productTitle}</h2>
        <img
          className="recommended-product-img"
          src={props.productImage}
          alt={props.alt}
        />
      </div>
      <div className="recommended-product-info-button-container">
        <div className="recommended-product-info-container">
          <IngredientsSmall ingredientsProduct={props.ingredientsProduct} />
          {/* 40px */}
          <span className="recommended-product-paragraph">
            <h4 className="recommendend-product-small-title">
              Personal compatibility:
            </h4>
            <p className="recommendend-product-small-text">
              {props.productInfo1}
            </p>
            <div className="recommended-product-personalization">
              <div
                className="recommended-product-personalization-list"
                // style={{ width: bestMatchFiltered.length > 0 && '370px' }}
              >
                <h3 className="recommended-product-personalization-title">
                  Relevant product impacts:
                </h3>
                <ul className="recommendend-product-list">
                  {personalSentenceComponents}
                </ul>
              </div>

              <div
                className="recommended-product-personalization-list"
                // style={{
                //   display: bestMatchFiltered.length === 0 && 'none',
                //   width: bestMatchFiltered.length > 0 && '370px'
                // }}
              >
                <h3 className="recommended-product-personalization-title">
                  Best match for you as you have:
                </h3>
                <ul className="recommendend-product-list">
                  {bestMatchSentencesComponents}
                </ul>
              </div>
            </div>
          </span>
          {/* 40px */}
          <span className="recommended-product-paragraph">
            <h4 className="recommendend-product-small-title">How to use:</h4>
            <ol
              className="recommendend-product-numbered-list"
              style={{ listStyleType: "none" }}
            >
              {props.children}
            </ol>
          </span>
        </div>

        <div className="recommended-product-button-container">
          <Button
            isOnStep={props.isOnStep}
            isButtonActive={props.isButtonActive}
            buttonText={buttonText}
            isOnRecommendedProduct={isOnRecommendedProduct}
            onClick={handleClick}
            isYellow={isButtonYellow}
          />
          <p className="recommended-product-button-additional-text">
            Choose{" "}
            <span className="recommended-product-button-additional-text_highlighted">
              {props.productTitle}
            </span>
            <br /> with 20% discount
          </p>
        </div>
      </div>
    </div>
  );
}
