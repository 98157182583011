import TopIngredients from "../TopIngredients/TopIngredients";
import ScoreCalculatedTopIngredients from "../TopIngredients/ScoreCalculatedTopIngredients";
import { useState, useEffect } from "react";
import renderTopIngredients from "../../utils/renderTopIngredients";
import useScreenSize from "../../utils/useScreenSize";

export default function Header(props) {

  const [width, breakpoint] = useScreenSize();
  

  //console.log("line 7 ", props);
  //render

  // const renderTopIngredients = () => {
  //   return props.isCalculatedTopIngredients ? (
  //     <ScoreCalculatedTopIngredients
  //       isTopIngrediantsOn={props.isTopIngrediantsOn}
  //       recommendedIngredients={props.recommendedIngredients}
  //       //getRecommendedIngredients={props.getRecommendedIngredients}
  //     />
  //   ) : (
  //     <TopIngredients isTopIngrediantsOn={props.isTopIngrediantsOn} />
  //   );
  // };

  const renderTopIngredients1 = () => {
    return props.isCalculatedTopIngredients ? (
      <ScoreCalculatedTopIngredients
        isTopIngrediantsOn={props.isTopIngrediantsOn}
        recommendedIngredients={props.recommendedIngredients}
      //getRecommendedIngredients={props.getRecommendedIngredients}
      />
    ) : (
      <TopIngredients
        isTopIngrediantsOn={props.isTopIngrediantsOn}
        recommendedIngredients={props.recommendedIngredients}
      />
    );
  };


  if (props.isOnStep === 1) {
    return (
      <div className="header">
        <button
          className="header-close-btn"
          onClick={props.closeTheQuiz}
        ></button>
        <div className="header-container">
          {
            (width >= breakpoint) && renderTopIngredients(props) /*TODO to check in inner step when i should i use it*/
          }
          <h1 className={`header-title ${window.innerWidth > 650 ? 'header-title_two-line' : ''}`}>Welcome to AI skincare assistant!</h1>
        </div>
      </div>
    );
  }
  if (props.isOnStep === 2) {
    return (
      <div className="header">
        <button
          className="header-close-btn"
          onClick={props.closeTheQuiz}
        ></button>
        <div className="header-container">
          {(width >= breakpoint) && renderTopIngredients(props)}
          <h1 className={`header-title ${window.innerWidth > 650 ? 'header-title_two-line' : ''}`}>Nice to meet you, {props.name}!</h1>
        </div>
      </div>
    );
  }
  if (props.isOnStep === 3) {
    return (
      <div className="header">
        <button
          className="header-close-btn"
          onClick={props.closeTheQuiz}
        ></button>

        <div className="header-container">
          {(width >= breakpoint) && renderTopIngredients(props)}
          <h1 className={`header-title ${window.innerWidth > 650 ? 'header-title_two-line' : ''}`}>
            {props.name}, your recommendations are almost there!
          </h1>
        </div>
      </div>
    );
  }
  if (props.isOnStep === 4) {
    return (
      <div className="header">
        <button
          className="header-close-btn"
          onClick={props.closeTheQuiz}
        ></button>
        <div className="header-container">
          {/*<TopIngredients isTopIngrediantsOn={props.isTopIngrediantsOn} />*/}
          {(width >= breakpoint) && renderTopIngredients(props)}
          <h1 className={`header-title ${window.innerWidth > 650 ? 'header-title_two-line' : ''}`}>
            All right, {props.name}, <br /> one last step to go!
          </h1>
        </div>
      </div>
    );
  }
  if (props.isOnStep === 5) {
    return (
      <div className="header header_recommendation">
        <div className="header-container">
          {/*<TopIngredients isTopIngrediantsOn={props.isTopIngrediantsOn} />*/}
          {(width >= breakpoint) && renderTopIngredients(props)}
          <h1
            className={
              props.isOnStep === 5
                ? "header-title header-title_center"
                : "header-title"
            }
          >
            Congratulations, {props.name}! Your personal recommendations are
            ready!
          </h1>
        </div>
      </div>
    );
  }

  if (props.isOnStep === 2.5) {
    return (
      <div className="header">
        <button
          className="header-close-btn"
          onClick={props.closeTheQuiz}
        ></button>
        <div className="header-container">
          {/*<TopIngredients isTopIngrediantsOn={props.isTopIngrediantsOn} />*/}
          {(width >= breakpoint) && renderTopIngredients(props)}
          <h1
            className={
              props.isOnStep === 2.5
                ? "header-title header-title_center"
                : "header-title"
            }
          >
            Halfway there!
          </h1>
        </div>
      </div>
    );
  }
  // if (props.isOnStep === 4.5) {
  //   return (
  //     <button
  //       className="header-close-btn"
  //       onClick={props.closeTheQuiz}
  //     ></button>
  //   );
  // }
}