import smoothie_peach from '../images/peach.svg';
import smoothie_strawberry from '../images/strawberry.svg';
import smoothie_mango from '../images/mango.svg';
import bisabolol from '../images/bisabolol.png';
import argan_oil from '../images/argan_oil.png';
import argania from '../images/argania.png';
import argania_active_comp from '../images/argania_active_comp.png';
import honey from '../images/honey.png';
import hemp_oil from '../images/hemp_oil.svg';
import bakuchiol from '../images/bakuchiol.png';
import iamlove from '../images/iamlove.png';
import detoxme from '../images/detoxme.png';
import recoverme from '../images/recoverme.png';
import cme from '../images/cme.png';

import sun01 from '../icons/sun_0-1.svg';
import sun24 from '../icons/sun_2-4.svg';
import sun05 from '../icons/sun_5.svg';
import cup13 from '../icons/cup_1-3.svg';
import cup46 from '../icons/cup_4-6.svg';
import cup7 from '../icons/cup_7.svg';
import discoloration from '../icons/discoloration.svg';
import dark_spots from '../icons/dark_spots.svg';

import footer_logo from '../icons/footer-logo.svg';

////////////////////////Used for slider////////////////////////

const marks = [
  {
    value: 0,
    label: '1',
  },
  {
    value: 25,
    label: '2',
  },
  {
    value: 50,
    label: '3',
  },
  {
    value: 75,
    label: '4',
  },
  {
    value: 100,
    label: '5',
  },
];

const BrownSliderStyles = {
  '& .MuiSlider-thumb': {
    color: '#0F2639',
    boxShadow: 'none',
  },
  '& .MuiSlider-track': {
    opacity: '0',
  },
  '& .MuiSlider-active': {
    boxShadow: 'none',
  },
  '& .MuiSlider-rail': {
    color: 'white',
    background:
      'linear-gradient(90deg, #F1ECE3, #E8DBC5, #FDDFD6, #EFD0AC, #C5AC8F, #A48161, #835F46, #5C4233, #392D26)',
    opacity: '1',
  },
};
const BlueSliderStyles = {
  '& .MuiSlider-thumb': {
    color: '#0F2639',
    boxShadow: 'none',
  },
  '& .MuiSlider-active': {
    boxShadow: 'none',
  },
  '& .MuiSlider-track': {
    opacity: '0',
  },
  '& .MuiSlider-rail': {
    color: 'white',
    background: 'linear-gradient(90deg, #D6F2FD 0%, #4CADCE)',
    opacity: '1',
  },
  '& .MuiSlider-markLabel': {
    color: '#4CADCE',
  },
};
const BlueYellowSliderStyles = {
  '& .MuiSlider-thumb': {
    color: '#0F2639',
    boxShadow: 'none',
  },
  '& .MuiSlider-active': {
    boxShadow: 'none',
  },
  '& .MuiSlider-track': {
    opacity: '0',
  },
  '& .MuiSlider-rail': {
    color: 'white',
    background: 'linear-gradient(90deg, #FEEF6C, #D6F2FD, #D6F2FD, #FEEF6C)',
    opacity: '1',
  },
  '& .MuiSlider-markLabel': {
    color: '#4CADCE',
  },
};
const YellowSliderStyles = {
  '& .MuiSlider-thumb': {
    color: '#0F2639',
    boxShadow: 'none',
  },
  '& .MuiSlider-active': {
    boxShadow: 'none',
  },
  '& .MuiSlider-track': {
    opacity: '0',
  },
  '& .MuiSlider-rail': {
    color: 'white',
    background: 'linear-gradient(90deg, #D6F2FD 0%, #FEEF6C 100%)',
    opacity: '1',
  },
  '& .MuiSlider-markLabel': {
    color: '#4CADCE',
  },
};

const QUESTIONS_AMOUNT = 17;
const QUESTION_WIDTH = 1200;
const QUESTION_WIDTH_1200_1000 = 850;
const QUESTION_WIDTH_999_768 = 740;

const stepsLength = {
  1: 3,
  2: 5,
  3: 4,
  4: 5
}


////////////////////////Used for slider////////////////////////

export {
  marks,
  BrownSliderStyles,
  BlueSliderStyles,
  BlueYellowSliderStyles,
  YellowSliderStyles,
  smoothie_peach,
  smoothie_strawberry,
  smoothie_mango,
  bisabolol,
  argan_oil,
  argania,
  argania_active_comp,
  honey,
  hemp_oil,
  bakuchiol,
  iamlove,
  detoxme,
  recoverme,
  cme,
  sun01,
  sun24,
  sun05,
  cup13,
  cup46,
  cup7,
  discoloration,
  dark_spots,
  footer_logo,
  QUESTIONS_AMOUNT,
  QUESTION_WIDTH,
  QUESTION_WIDTH_1200_1000,
  QUESTION_WIDTH_999_768,
  stepsLength,
};
