import SliderContainer from "../SliderContainer/SliderContainer";
import Slider from "@mui/material/Slider";
import { useEffect } from "react";
import { useState } from 'react';


export default function SliderQuestion(props) {
  const [currentScore, setCurrentScore] = useState(props.score[props.defaultSliderValue]);
  const [isSliderChanged, setIsSliderChanged] = useState(false);
  const [isNextClicked, setIsNextClicked] = useState(false);

  const { tip } = props;
  const { TipPopup } = props;

  function handleChange(e, newValue) {
    let newScores = {};
    if (!isSliderChanged) {
      if (isNextClicked) {
        for (let key in props.score[props.defaultSliderValue]) {
          newScores[key] = props.userScores[key] - props.score[props.defaultSliderValue][key];
        }
      }
    } else {
      for (let key in currentScore) {
        newScores[key] = props.userScores[key] - currentScore[key];
      }
    }

    for (let key in props.score[newValue]) {
      const temp = newScores[key] !== undefined ? newScores[key] : props.userScores[key];
      newScores[key] = temp + props.score[newValue][key];
    }
    setIsSliderChanged(true);
    props.updateUserScores(newScores);
    setCurrentScore(props.score[newValue]);

    if (props.isBonus) {
      props.updateUserResponses(props.step, props.question, newValue, props.isBonus, props.bonusQuestion);
    } else {
      props.updateUserResponses(props.step, props.question, newValue);
    }

    // props.getRecommendedIngredients(props.topIngredientsNumber);
  }

  function handleNextClick() {
    if (!isSliderChanged && !isNextClicked) {
      for (let key in props.score[props.defaultSliderValue]) {
        const newScores = [];
        newScores[key] = props.userScores[key] + props.score[props.defaultSliderValue][key];
        props.updateUserScores(newScores);
      }
      //props.getRecommendedIngredients(props.userScores);
    }

    setIsNextClicked(true);

    if (props.isBonus) {
      if (props.isLastBonus) {
        props.returnFromBonusQuestion(true);
        if (props.setBonusCounter) props.setBonusCounter(0);
      } else {
        props.nextBonusQuestion();
        if (props.setBonusCounter) props.setBonusCounter(props.bonusCounter + 1);
      }
    } else {
      props.nextInnerStep();
    }
  }

  function handleBackClick() {
    if (props.isBonus) {
      if (props.bonusQuestion === 'BQ1') {
        props.returnFromBonusQuestion(false);
        props.setBonusCounter(0);
      } else {
        props.previousBonusQuestion();
        props.setBonusCounter(props.bonusCounter - 1);
      }
    } else {
      props.previousInnerStep();
    }
  }

  useEffect(() => {
    //console.log(currentScore);
  }, [currentScore]);

  return (
    <div className="question-container" style={{display: props.isInvisible && "none"}}>
      <div
        className={
          props.isOnImgMultiSelectResponses
            ? 'question question_no-margin'
            : 'question'
        }
      >
        <div
          className={
            props.titleLines === 2
              ? 'question-title question-title_two-line'
              : 'question-title'
          }
        >
         
       
        {props.TipPopup ? <TipPopup tip={tip} /> : ""}
        {props.questionTitle}
        </div>
        <SliderContainer
          texts={props.texts}
          sliderTextLeft={props.sliderTextLeft}
          sliderTextRight={props.sliderTextRight}
        >
          <Slider
            defaultValue={props.defaultSliderValue}
            marks={props.marks}
            step={null}
            sx={props.sliderStyle}
            onChange={handleChange}
          />
        </SliderContainer>

        <div className="question-button-container">
          <button
            className="question-back-button"
            type='button'
            onClick={handleBackClick} />
          <button
            className='button'
            type='button'
            onClick={handleNextClick} >next</button>
        </div>
      </div>
    </div>
  )
}