import Button from "../Button/Button";
import {buildCartLinkSet,calculateDiscount} from "../../utils/CartUtils" 

const ImgUrl = `https://aiodefilesbucket.s3.amazonaws.com/freprod/{id}.webp`;

export default function RecommendedSet(props) {
  let imgProductComponents = [];
  if (props.imagesSetProducts !== undefined) {
    imgProductComponents = props.imagesSetProducts.map((ID) => (
      <div key={ID} onClick={() => props.scrollToProduct(ID)}>
        <div
          className="recommended-set-img"
          style={{
            backgroundImage: `url(${ImgUrl.replace("{id}", ID)})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          // src={ImgUrl.replace("{id}", ID)}
          // alt="img-recommended-product"
        />
      </div>
    ));
  }
 
  const handleClick = () => {
   if(props.discount === 20){
    props.setDiscount(40);
   } 
   props.setCartUrl(buildCartLinkSet(props.cartID,props.cartUrl));
   props.setItemCount(prevCount => prevCount + 5);
   props.setPrice(prevPrice => prevPrice+(props.productPrices-calculateDiscount(props.discount,props.productPrices)));
  }

  return (
    <div className="recommended-set">
      <div className="recommended-set-img-container">
        {imgProductComponents}
      </div>
      <div className="recommended-set-bottom">
        <div className="recommended-set-btn-container">
          <Button
            isButtonActive={props.isButtonActive}
            buttonText={"add my FRÉ routine to cart"}
            isOnStep={props.isOnStep}
            onClick={handleClick}
          />
          <p className="recommended-set-text">
            Your  MyFre40 coupon for  40% discount will be applied automatically
            </p>
          {/* <div className="recommended-set-container-text">
            
            {/* <p className="recommended-set-text regular">{`will be applied automatically`}</p> */}
            {/*<p className="recommended-set-text bold">Your price $480</p>
          </div> */}
        </div>

        {/* TODO - for future use
        <div className="recommended-set-context-container">
          <div className="recommended-set-btn-container">
            <Button
              isButtonActive={props.isButtonActive}
              buttonText={"Buy the extended set"}
              isOnStep={props.isOnStep}
              isExtendRecommend={true}
            />
            <div className="recommended-set-container-text">
              <p className="recommended-set-text regular">Regular price $600</p>
              <p className="recommended-set-text bold">Your price $480</p>
            </div>
          </div>
          <p className="recommended-set-container-text one-line">
            The set goes with the basis and extensions
          </p>
        </div>
  */}
      </div>
    </div>
  );
}
