import { useEffect, useState } from "react"

export default function SingleChoiceQuestion(props) {
  const [selectedScore, setSelectedScore] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isChoice, setIsChoice] = useState(false);
  const { tip } = props;
  const { TipPopup } = props;

  function handleClick(e) {
    setIsChoice(true);
    if (e.target.value === selectedOption) {
      if (props.isBonus) {
        if (props.isLastBonus) {          
          if(props.resetBonusQuestions) {props.resetBonusQuestions();}          
          props.returnFromBonusQuestion(true);                    
        } else {
          props.nextBonusQuestion();
        }
      } else {
        props.nextInnerStep();
      }
    }
  }

  function handleBackClick() {
    if (props.isBonus) {
      if (props.bonusQuestion === 'BQ1') {
        props.returnFromBonusQuestion(false);
        props.setBonusCounter(0);
        if(props.resetBonusQuestions) {props.resetBonusQuestions();}
      } else {
        props.previousBonusQuestion();
        props.setBonusCounter(props.bonusCounter - 1);
      }
    } else {
      props.previousInnerStep();
    }
  }

  function handleNextClick() {
    if (props.isBonus) {
      if (props.isLastBonus) {
        props.returnFromBonusQuestion(true);
        if (props.setBonusCounter) props.setBonusCounter(0);
        if(props.resetBonusQuestions) {props.resetBonusQuestions();}
      } else {
        props.nextBonusQuestion();
        if (props.setBonusCounter) props.setBonusCounter(props.bonusCounter + 1);
      }
    } else {
      props.nextInnerStep();
    }
  }

  function handleChange(e) {
    setTimeout(setIsChoice(true), 1000);
    // setIsChoice(true);
    let newScores = [];
    const response = props.options.find(option => option.responseText === e.target.value);

    if (selectedScore) {
      for (let key in selectedScore) {
        const newScore = response.score ? response.score[key] : 0;
        newScores[key] = props.userScores[key] - selectedScore[key] + newScore;
        props.updateUserScores(newScores);
      }
    } else {
      if (response.score) {
        for (let key in response.score) {
          newScores[key] = props.userScores[key] + response.score[key];
          props.updateUserScores(newScores);
        }
      }
    }
    if (props.isBonus) {
      props.updateUserResponses(props.step, props.question, e.target.value, props.isBonus, props.bonusQuestion);
    } else {
      props.updateUserResponses(props.step, props.question, e.target.value);
    }

    setSelectedScore(response.score);
    setSelectedOption(e.target.value);

    // handleClick(e);

    if (props.isBonus) {
      if (props.isLastBonus) {
        if(props.resetBonusQuestions) {props.resetBonusQuestions();} 
        props.returnFromBonusQuestion(true);
        if (props.setBonusCounter) props.setBonusCounter(0);
      } else {
        props.nextBonusQuestion();
        if (props.setBonusCounter) props.setBonusCounter(props.bonusCounter + 1);
      }
    } else {
      props.nextInnerStep();
    }

    // props.getRecommendedIngredients(props.topIngredientsNumber);
  }

  return (
    <div className="question-container" style={{ display: props.isInvisible && "none" }}>
      <div
        className={
          props.isOnImgMultiSelectResponses
            ? 'question question_no-margin'
            : 'question'
        }
      >
        
        <div className='single-choice-question'>
          <div
            className={
              props.titleLines === 2
                ? 'question-title question-title_two-line'
                : 'question-title'
            }
          >
            {props.TipPopup ? <TipPopup tip={tip} /> : ""}
            {props.questionTitle}
            
          </div>
          
          <form className='single-choice-question-form' onSubmit={props.nextInnerStep}>
            <div className="single-choice-question-content">
              {props.options.map((option, index) =>
              (
                <label key={index} className='single-choice-question-label' >
                  <input
                    className="single-choice-question-input"
                    type='radio'
                    value={option.responseText}
                    name={props.questionTitle}
                    key=''
                    onChange={handleChange}
                    // onClick={handleClick}
                  />
                  <div className='single-choice-question-option'>
                    {option.icon && (
                      <img src={option.icon} className='single-choice-question-icon' />
                    )}
                    <p className='single-choice-question-response'>{option.responseText}</p>
                  </div>
                </label>
              )
              )}
            </div>

            <div className="question-button-container">
              <button
                className="question-back-button"
                type='button'
                onClick={handleBackClick} />
              {isChoice
                && <button
                  type='button'
                  className='button'
                  onClick={handleNextClick}>next</button>}
            </div>
          </form>
        </div>

      </div>
    </div>

  )
}