import { sun01, sun24, sun05 } from "../../utils/constants";
import useScreenSize from "../../utils/useScreenSize";
import SingleChoiceQuestion from "../SingleChoiceQuestion/SingleChoiceQuestion";
import SliderQuestion from "../SliderQuestion/SliderQuestion";
import TipPopup from "../TipPopup/TipPopup";

import {
  marks,
  YellowSliderStyles,
  cup13,
  cup46,
  cup7,
} from "../../utils/constants";

export default function Step4(props) {
  const [width, breakpoint] = useScreenSize();

  const step = "Step4";

  const { AllQtipsFunc } = props;

  function selectQItems(questionKey) {
    const selectedItems = AllQtipsFunc.filter(item =>
      item.question === questionKey
    );
    return selectedItems;
  }

  if (width >= breakpoint) {
    return (
      <>
        <SingleChoiceQuestion
          tip={selectQItems('Q1')[0]}
          TipPopup={TipPopup}
          getRecommendedIngredients={props.getRecommendedIngredients}
          topIngredientsNumber={3}
          questionTitle={"How much time do you spend in direct sunlight each day?"}
          titleLines={2}
          nextInnerStep={props.nextInnerStep}
          previousInnerStep={props.previousInnerStep}
          userScores={props.userScores}
          updateUserScores={props.updateUserScores}
          updateUserResponses={props.updateUserResponses}
          step={step}
          question={'Q1'}
          options={[
            {
              responseText: '0 - 1',
              icon: sun01
            },
            {
              responseText: '2 - 4',
              score: { Photoaging: 1 },
              icon: sun24
            },
            {
              responseText: '5+',
              score: { Photoaging: 2 },
              icon: sun05
            }
          ]}
        />

        <SingleChoiceQuestion
          tip={selectQItems('Q2')[0]}
          TipPopup={TipPopup}
          getRecommendedIngredients={props.getRecommendedIngredients}
          topIngredientsNumber={3}
          questionTitle={"Do you smoke?"}
          titleLines={props.titleLines}
          nextInnerStep={props.nextInnerStep}
          previousInnerStep={props.previousInnerStep}
          userScores={props.userScores}
          updateUserScores={props.updateUserScores}
          updateUserResponses={props.updateUserResponses}
          step={step}
          question={'Q2'}
          options={[
            {
              responseText: 'Yes',
              score: { Photoaging: 2 }
            },
            {
              responseText: 'No',
            },
            {
              responseText: 'Sometimes',
              score: { Photoaging: 1 },
            }
          ]}
        />

        <SingleChoiceQuestion
          getRecommendedIngredients={props.getRecommendedIngredients}
          topIngredientsNumber={3}
          questionTitle={"Do you prefer to use a tinted day cream?"}
          titleLines={props.titleLines}
          nextInnerStep={props.nextInnerStep}
          previousInnerStep={props.previousInnerStep}
          userScores={props.userScores}
          updateUserScores={props.updateUserScores}
          updateUserResponses={props.updateUserResponses}
          step={step}
          question={'Q3'}
          options={[
            { responseText: 'Yes' },
            { responseText: 'No' }
          ]}
        />
        <SliderQuestion
          tip={selectQItems('Q4')[0]}
          TipPopup={TipPopup}
          getRecommendedIngredients={props.getRecommendedIngredients}
          topIngredientsNumber={3}
          titleLines={props.titleLines}
          questionTitle={"How stressed are you in your daily life?"}
          sliderTextLeft="Not at all I’m super chill"
          sliderTextRight="All the time, life is hard"
          sliderStyle={YellowSliderStyles}
          texts={2}
          marks={marks}
          defaultSliderValue={50}
          nextInnerStep={props.nextInnerStep}
          previousInnerStep={props.previousInnerStep}
          userScores={props.userScores}
          updateUserScores={props.updateUserScores}
          updateUserResponses={props.updateUserResponses}
          step={step}
          question={'Q4'}
          score={{
            0: { Photoaging: 0 },
            25: { Photoaging: 0 },
            50: { Photoaging: 1 },
            75: { Photoaging: 2 },
            100: { Photoaging: 3 }
          }} />

        <SingleChoiceQuestion
          getRecommendedIngredients={props.getRecommendedIngredients}
          topIngredientsNumber={3}
          questionTitle={"How many glasses of water do you drink per day?"}
          titleLines={props.titleLines}
          nextInnerStep={props.nextInnerStep}
          previousInnerStep={props.previousInnerStep}
          userScores={props.userScores}
          updateUserScores={props.updateUserScores}
          updateUserResponses={props.updateUserResponses}
          step={step}
          question={'Q5'}
          options={[
            {
              responseText: '1 - 3',
              score: { 'Dry Skin': 1 },
              icon: cup13
            },
            {
              responseText: '4 - 6',
              icon: cup46
            },
            {
              responseText: '7+',
              icon: cup7
            }
          ]}
        />
      </>
    );
  }
  if (width < breakpoint) {
    return (<>
      {        
        <SingleChoiceQuestion
          tip={selectQItems('Q1')[0]}
          TipPopup={TipPopup}
          isInvisible={!(props.isOnStep === 4 && props.step4InnerCount === 1)}
          getRecommendedIngredients={props.getRecommendedIngredients}
          topIngredientsNumber={3}
          questionTitle={"How many hours do you spend under direct sun exposure per day?"}
          titleLines={2}
          nextInnerStep={props.nextInnerStep}
          previousInnerStep={props.previousInnerStep}
          userScores={props.userScores}
          updateUserScores={props.updateUserScores}
          updateUserResponses={props.updateUserResponses}
          step={step}
          question={'Q1'}
          options={[
            {
              responseText: '0 - 1',
              icon: sun01
            },
            {
              responseText: '2 - 4',
              score: { Photoaging: 1 },
              icon: sun24
            },
            {
              responseText: '5+',
              score: { Photoaging: 2 },
              icon: sun05
            }
          ]}
        />
      }
      {        
        <SingleChoiceQuestion
          tip={selectQItems('Q2')[0]}
          TipPopup={TipPopup}
          getRecommendedIngredients={props.getRecommendedIngredients}
          isInvisible={!(props.isOnStep === 4 && props.step4InnerCount === 2)}
          topIngredientsNumber={3}
          questionTitle={"Do you smoke?"}
          titleLines={props.titleLines}
          nextInnerStep={props.nextInnerStep}
          previousInnerStep={props.previousInnerStep}
          userScores={props.userScores}
          updateUserScores={props.updateUserScores}
          updateUserResponses={props.updateUserResponses}
          step={step}
          question={'Q2'}
          options={[
            {
              responseText: 'Yes',
              score: { Photoaging: 2 }
            },
            {
              responseText: 'No',
            },
            {
              responseText: 'Sometimes',
              score: { Photoaging: 1 },
            }
          ]}
        />
      }
      {        
        <SingleChoiceQuestion
          getRecommendedIngredients={props.getRecommendedIngredients}
          isInvisible={!(props.isOnStep === 4 && props.step4InnerCount === 3)}
          topIngredientsNumber={3}
          questionTitle={"Do you prefer to use a tinted day cream?"}
          titleLines={props.titleLines}
          nextInnerStep={props.nextInnerStep}
          previousInnerStep={props.previousInnerStep}
          userScores={props.userScores}
          updateUserScores={props.updateUserScores}
          updateUserResponses={props.updateUserResponses}
          step={step}
          question={'Q3'}
          options={[
            { responseText: 'Yes' },
            { responseText: 'No' }
          ]}
        />
      }
      {        
        <SliderQuestion
          tip={selectQItems('Q4')[0]}
          TipPopup={TipPopup}
          getRecommendedIngredients={props.getRecommendedIngredients}
          isInvisible={!(props.isOnStep === 4 && props.step4InnerCount === 4)}
          topIngredientsNumber={3}
          questionTitle={"How stressed are you in your daily life?"}
          sliderTextLeft="Not at all I’m super chill"
          sliderTextRight="All the time, life is hard"
          sliderStyle={YellowSliderStyles}
          texts={2}
          marks={marks}
          defaultSliderValue={50}
          nextInnerStep={props.nextInnerStep}
          previousInnerStep={props.previousInnerStep}
          userScores={props.userScores}
          updateUserScores={props.updateUserScores}
          updateUserResponses={props.updateUserResponses}
          step={step}
          question={'Q4'}
          score={{
            0: { Photoaging: 0 },
            25: { Photoaging: 0 },
            50: { Photoaging: 1 },
            75: { Photoaging: 2 },
            100: { Photoaging: 3 }
          }} />
      }
      {        
        <SingleChoiceQuestion
          getRecommendedIngredients={props.getRecommendedIngredients}
          isInvisible={!(props.isOnStep === 4 && props.step4InnerCount === 5)}
          topIngredientsNumber={3}
          questionTitle={"How many glasses of water do you drink per day?"}
          titleLines={props.titleLines}
          nextInnerStep={props.nextInnerStep}
          previousInnerStep={props.previousInnerStep}
          userScores={props.userScores}
          updateUserScores={props.updateUserScores}
          updateUserResponses={props.updateUserResponses}
          step={step}
          question={'Q5'}
          options={[
            {
              responseText: '1 - 3',
              score: { 'Dry Skin': 1 },
              icon: cup13
            },
            {
              responseText: '4 - 6',
              icon: cup46
            },
            {
              responseText: '7+',
              icon: cup7
            }
          ]}
        />
      }
    </>
    );
  }
}
