import { useEffect, useState, useRef } from 'react';

export default function ImagesQuestion(props) {
  const [answers, setAnswers] = useState([]);
  const buttonTextRef = useRef(null);

  function handleCheckboxChange(e) {
    let newScores = [];
    const response = props.options.find(option => option.responseText === e.target.value);
    
    if (answers.includes(e.target.value)) {
      const newAnswers = answers.filter(item => item !== e.target.value);
      setAnswers(newAnswers);
      for (let key in response.score) {
        newScores[key] = props.userScores[key] - response.score[key];
        props.updateUserScores(newScores);
      }
      props.handleMultiResponses(props.step, e.target.value, 'remove', props.question);
    } else {
      setAnswers((prev) => [...prev, e.target.value]);
      for (let key in response.score) {
        newScores[key] = props.userScores[key] + response.score[key];
        props.updateUserScores(newScores);
      }
      props.handleMultiResponses(props.step, e.target.value, 'push', props.question);
    }
  };

  function handleSubmit(e) {
    e.preventDefault();

    let isChosenBonus = false;
    props.options.forEach((option) => {
      if (answers.includes(option.responseText) && option.activateBonus) {
        isChosenBonus = true;
      }
    });

    if (isChosenBonus) {
      props.activateBonusQuestions();
    } else {
      props.nextInnerStep();
    }
  }

  useEffect(() => {
    if (answers.length === 0) {
      buttonTextRef.current.innerText = "none";
    } else {
      buttonTextRef.current.innerText = "next";
    }
  }, [answers]);

  return (
    <div 
    className="question-container" 
    style={{display: props.isInvisible && "none", opacity: (props.displayBonusQuestions && props.step === 'Step3' && props.question === 'Q3') && '0'}}>
      {/* <div
        className={
          props.displayBonusQuestions
            ? 'images-question images-question_disabled'
            : 'images-question'
        }
      > */}
        <div
          className='question'
        >
          <div
            className={
              props.titleLines === 2
                ? 'question-title question-title_two-line'
                : 'question-title'
            }>
            {props.questionTitle}
          </div>
          <form className='multi-choice-question-form' onSubmit={handleSubmit} style={{ width: props.width }}>
            <div className='multi-choice-question-question-content'>
              {props.options.map((option, index) => (
                <label key={index} className='multi-choice-question-label'>
                  <div className='multi-choice-question-response-container'>
                    <input
                      className='multi-choice-question-input'
                      type='checkbox'
                      name={option.responseText}
                      value={option.responseText}
                      onChange={handleCheckboxChange} />
                    <span className='multi-choice-question-checkbox' />
                    <p className={`multi-choice-question-response ${option.isCapillaries ? 'images-question-response-long' : ''}`}>{option.responseText}</p>
                  </div>

                  {/* <div className='images-question-image-box'>
                    <img className='images-question-image' src={option.image} />
                  </div> */}
                </label>
              ))}
            </div>
            <div className="question-button-container">
              <button
                className="question-back-button"
                type='button'
                onClick={props.previousInnerStep} />
              <button
                className={props.isButtonActive ? 'button' : 'button_inactive'}
                type='submit'
                ref={buttonTextRef} />
            </div>
          </form>
        </div>
      {/* </div> */}
    </div>
  )
}

/* <div className="question-container_images" style={{display: props.isInvisible && "none"}}>
      <div
        className={
          props.displayBonusQuestions
            ? 'images-question images-question_disabled'
            : 'images-question'
        }
      >
        <div
          className={
            props.isOnImgMultiSelectResponses
              ? 'images-question-content question question_no-margin'
              : 'question'
          }
        >
          <div
            className={
              props.titleLines === 2
                ? 'question-title question-title_two-line'
                : 'question-title'
            }>
            {props.questionTitle}
          </div>
          <form className='images-question-form' onSubmit={handleSubmit}>
            <div className='images-question-question-content'>
              {props.options.map((option, index) => (
                <label key={index} className='images-question-label'>
                  <div className='images-question-response-container'>
                    <input
                      className='images-question-input'
                      type='checkbox'
                      name={option.responseText}
                      value={option.responseText}
                      onChange={handleCheckboxChange} />
                    <span className='images-question-checkbox' />
                    <p className={`images-question-response ${option.isCapillaries ? 'images-question-response-long' : ''}`}>{option.responseText}</p>
                  </div>

                  <div className='images-question-image-box'>
                    <img className='images-question-image' src={option.image} />
                  </div>
                </label>
              ))}
            </div>
            <div className="question-button-container">
              <button
                className="question-back-button"
                type='button'
                onClick={props.previousInnerStep} />
              <button
                className={props.isButtonActive ? 'button' : 'button_inactive'}
                type='submit'
                ref={buttonTextRef} />
            </div>
          </form>
        </div>
      </div>
    </div> */