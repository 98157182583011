import "normalize.css";
import "./index.css";
import { useState, useEffect } from "react";
import Main from "./components/Main/Main";
import Header from "./components/Header/Header";
import Introduction from "./components/Introduction/Introduction";
import SmoothieStep from "./components/SmoothieStep/SmoothieStep";
import PersonalRecommendation from "./components/PersonalRecommendation/PersonalRecommendation";
import UserScores from "./data/UserScores.json";
import Responses from "./data/Responses.json";
import {
  QUESTIONS_AMOUNT,
  QUESTION_WIDTH,
  QUESTION_WIDTH_1200_1000,
  QUESTION_WIDTH_999_768,
  stepsLength,
} from "./utils/constants";
import EmailStep from "./components/EmailStep/EmailStep";
import * as api  from "./api/apiFunctions.js"

function App() {
  const [isOnStep, setIsOnStep] = useState(0);
  const [step1InnerCount, setStep1InnerCount] = useState(1);
  const [step2InnerCount, setStep2InnerCount] = useState(1);
  const [step3InnerCount, setStep3InnerCount] = useState(1);
  const [step4InnerCount, setStep4InnerCount] = useState(1);
  const [containerPosition, setContainerPosition] = useState({ x: 0, y: 0 });
  const [isTopIngrediantsOn, setIsTopIngrediantsOn] = useState(true);
  const [isButtonActive, setIsButtonActive] = useState(true);
  const [isOnMultiSelectResponses, setIsOnMultiSelectResponses] =
    useState(true);
  const [isOnImgMultiSelectResponses, setIsOnImgMultiSelectResponses] =
    useState(false);
  const [userScores, setUserScores] = useState(UserScores);
  const [responses, setResponses] = useState(Responses);
  const [qtips, setQtips] = useState([])
  const [recommendedIngredients, setRecommendedIngredients] = useState([]); //A list of 3 recommended ingredients objects.
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [personalSentences, setPersonalSentences] = useState([]);
  const [bestMatchSentences, setBestMatchSentences] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [smoothieIngredients, setSmoothieIngredients] = useState([]);
  const [smoothie, setSmoothie] = useState();
  // const [smoothieName, setSmoothieName] = useState();
  const [isCalculatedTopIngredients, setIsCalculatedTopIngredients] =
    useState(false);
  const [isResultReady, setIsResultReady] = useState(false);
  const [displayPigmentationQuestions, setDisplayPigmentationQuestions] =
    useState(false);
  const [displayAcneQuestions, setDisplayAcneQuestions] = useState(false);
  const [displayRednessQuestions, setDisplayRednessQuestions] = useState(false);
  const [isNameEmpty, setIsNameEmpty] = useState(false);
  const [currentX, setCurrentX] = useState(0);

  const [bonusCounter, setBonusCounter] = useState(0);

  const [openedQuestions, setOpenedQuestions] = useState({
    1: {
      1: true,
      2: false,
      3: false,
    },
    2: {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
    },
    3: {
      1: false,
      2: false,
      3: false,
      4: false,
    },
    4: {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
    },
  });
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }


  useEffect( () => {
    // get q-tips from data
     const getQtips = async () => { setQtips( await api.getAllQtips())};
     getQtips();
   
  }, []);
  
  useEffect(() => {
    
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const containerStyle = {
    width: `calc(${QUESTIONS_AMOUNT} * ${
      screenSize.width > 1200
        ? QUESTION_WIDTH
        : screenSize.width > 999
        ? QUESTION_WIDTH_1200_1000
        : QUESTION_WIDTH_999_768
    }px)`,
    transform: `translateX(${containerPosition.x}px)`,
    transition: "transform 0.4s ease-in-out",
  };

  const containerStyleBonusStep = {
    width: `calc(${QUESTIONS_AMOUNT} * ${
      screenSize.width > 1200
        ? QUESTION_WIDTH
        : screenSize.width > 999
        ? QUESTION_WIDTH_1200_1000
        : QUESTION_WIDTH_999_768
    }px)`,
    transform: `translateX(${containerPosition.x}px)`,
    transition: "transform  ease-in-out",
  };

  const containerStyleBonus = {
    transform: `translateY(${containerPosition.y}px)`,
    transition: "translateX ease-in-out translateY 0.4s ease-in-out",

    // transition: 'translateY 0.4s ease-in-out',
    // transform: `translateX(${containerPosition.x}px) translateY(${containerPosition.y}px)`,
    // transition: 'transform  ease-in-out',
  };

  const containerStyleY = {
    transform: `translateY(${containerPosition.y}px)`,
    transition:
      window.innerWidth >= 768 ? "transform 0.4s ease-in-out" : "all 0s",
  };

  //help function
  const intermediateResult = () => {
    setIsOnStep(2.5);
    setIsTopIngrediantsOn(false);
  };

  const displayEmailPage = async () => {
    setIsOnStep(4.5);
    setIsTopIngrediantsOn(false);

    const response = await api.getProducts(userScores,responses)
 
    setRecommendedProducts(response.productForClient);
    setPersonalSentences(response.personalSentencesCompatibility);
    setBestMatchSentences(response.bestMatch);
    setRecommendedIngredients(response.displayIngredients);
    // console.log("isOnStep:", isOnStep);
    setTimeout(() => {
      setIsResultReady(true); //for loader on smoothie step
    }, 3000);
  };

  // const displaySmoothiePage = () => {
  //   setIsOnStep(4.5);
  //   setIsTopIngrediantsOn(false);
  //   getProducts();
  // };
  const displayPersonalRecommendation = () => {
    setIsOnStep(5);
    api.klaviyoConnection(email,recommendedProducts);
    
  };

  const closeIntermediateResult = () => {
    setIsOnStep(3);
    setIsTopIngrediantsOn(true);
    setIsOnMultiSelectResponses(false);
    setIsOnImgMultiSelectResponses(true);
  };

  const positionController = (value) => {
    const positionX = containerPosition.x - value.x;
    const positionY = containerPosition.y - value.y;
    const newPosition = { x: positionX, y: positionY };
    setContainerPosition(newPosition);
  };
  const previousPositionController = (value) => {
    const positionX = containerPosition.x + value.x;
    const positionY = containerPosition.y + value.y;
    const newPosition = { x: positionX, y: positionY };
    setContainerPosition(newPosition);
  };

  // console.log('x:' + containerPosition.x, 'y:' + containerPosition.y);

  const closeTheQuiz = () => {
    setIsOnStep(null);
    setIsTopIngrediantsOn(false);
    setIsButtonActive(false);
  };

  const nextStep = async () => {
    if (isOnStep === 0) {
      setRecommendedIngredients(await api.getAllIngredients());
    }
    
    setIsOnStep(isOnStep + 1);
  };
  const previousStep = () => {
    setIsOnStep(isOnStep - 1);
  };

  const activatePigmentationQuestions = () => {
    setCurrentX(containerPosition.x);
    setDisplayPigmentationQuestions(true);
    positionController({ x: 0, y: screenSize.width >= 768 ? 260 : 750 });
    setBonusCounter(1);
  };
  const activateAcneQuestions = () => {
    setCurrentX(containerPosition.x);
    setDisplayAcneQuestions(true);
    positionController({ x: 0, y: screenSize.width >= 768 ? 260 : 750 });
    setBonusCounter(1);
  };
  const activateRednessQuestions = () => {
    setCurrentX(containerPosition.x);
    setDisplayRednessQuestions(true);
    positionController({ x: 0, y: screenSize.width >= 768 ? 360 : 750 });
    setBonusCounter(1);
  };

  // function to move to the next bonus question without increasing the innerStep count:
  const nextBonusQuestion = async () => {
    positionController({
      x:
        screenSize.width > 1200
          ? QUESTION_WIDTH
          : screenSize.width > 999
          ? QUESTION_WIDTH_1200_1000
          : QUESTION_WIDTH_999_768,
      y: 0,
    });
    setRecommendedIngredients(await api.getRecommendedIngredients(userScores));
  };

  const previousBonusQuestion = () => {
    positionController({
      x: -(screenSize.width > 1200
        ? QUESTION_WIDTH
        : screenSize.width > 999
        ? QUESTION_WIDTH_1200_1000
        : QUESTION_WIDTH_999_768),
      y: 0,
    });
  };

  // function to return from bonus question:
  const returnFromBonusQuestion = async (isLastBonus) => {
    setRecommendedIngredients(await api.getRecommendedIngredients(userScores));
    const newX = isLastBonus
      ? currentX -
        (screenSize.width > 1200
          ? QUESTION_WIDTH
          : screenSize.width > 999
          ? QUESTION_WIDTH_1200_1000
          : QUESTION_WIDTH_999_768)
      : currentX;
    if (displayPigmentationQuestions) {
      const delayY = () => {
        setContainerPosition({ x: newX, y: 0 });
      };

      const disableBonusQuestion = () => {
        setDisplayPigmentationQuestions(false);
        setDisplayAcneQuestions(false);
        setDisplayRednessQuestions(false);
      };
      // setContainerPosition({ x: 0 });
      setContainerPosition({ x: newX });
      if (isLastBonus) {
        setOpenedQuestions((prev) => ({
          ...prev,
          [isOnStep]: {
            ...prev[isOnStep],
            [step3InnerCount + 1]: true,
          },
        }));
        setStep3InnerCount(step3InnerCount + 1);
      }
      //setContainerPosition({ x: newX, y: 0 });
      setTimeout(delayY, window.innerWidth >= 768 ? 400 : 0);
      //disableBonusQuestion();
      setTimeout(disableBonusQuestion, window.innerWidth >= 768 ? 600 : 0);
    }
    if (displayAcneQuestions) {
      const delayY = () => {
        setContainerPosition({ x: newX, y: 0 });
      };

      const disableBonusQuestion = () => {
        setDisplayPigmentationQuestions(false);
        setDisplayAcneQuestions(false);
        setDisplayRednessQuestions(false);
      };
      setContainerPosition({ x: newX });
      if (isLastBonus) {
        setOpenedQuestions((prev) => ({
          ...prev,
          [isOnStep]: {
            ...prev[isOnStep],
            [step3InnerCount + 1]: true,
          },
        }));
        setStep3InnerCount(step3InnerCount + 1);
      }
      setTimeout(delayY, window.innerWidth >= 768 ? 400 : 0);
      setTimeout(disableBonusQuestion, window.innerWidth >= 768 ? 600 : 0);
    }
    if (displayRednessQuestions) {
      // const newX = isLastBonus ? -3200 : -2200;
      const delayY = () => {
        setContainerPosition({ x: newX, y: 0 });
      };

      const disableBonusQuestion = () => {
        setDisplayPigmentationQuestions(false);
        setDisplayAcneQuestions(false);
        setDisplayRednessQuestions(false);
      };
      setContainerPosition({ x: newX });
      if (isLastBonus) {
        setOpenedQuestions((prev) => ({
          ...prev,
          [isOnStep]: {
            ...prev[isOnStep],
            [step3InnerCount + 1]: true,
          },
        }));
        setStep3InnerCount(step3InnerCount + 1);
      }
      setTimeout(delayY, window.innerWidth >= 768 ? 400 : 0);
      setTimeout(disableBonusQuestion, window.innerWidth >= 768 ? 600 : 0);
    }
  };

  //TODO getRecommendedIngredients and to find the user score
  const nextInnerStep = async () => {
    positionController({
      x:
        screenSize.width > 1200
          ? QUESTION_WIDTH
          : screenSize.width > 999
          ? QUESTION_WIDTH_1200_1000
          : QUESTION_WIDTH_999_768,
      y: 0,
    });
    if (isOnStep === 1) {
      if (step1InnerCount === 3) {
        // getRecommendedIngredients(2);
        setOpenedQuestions((prev) => ({
          ...prev,
          [isOnStep + 1]: {
            ...prev[isOnStep + 1],
            [step2InnerCount]: true,
          },
        }));
        nextStep();
        setStep2InnerCount(1);
      } else {
        setOpenedQuestions((prev) => ({
          ...prev,
          [isOnStep]: {
            ...prev[isOnStep],
            [step1InnerCount + 1]: true,
          },
        }));
        setStep1InnerCount(step1InnerCount + 1);
      }
    }
    //TODO getRecommendedIngredients  and to find the user score
    if (isOnStep === 2) {
      positionController({
        x:
          screenSize.width > 1200
            ? QUESTION_WIDTH
            : screenSize.width > 999
            ? QUESTION_WIDTH_1200_1000
            : QUESTION_WIDTH_999_768,
        y: 0,
      });
      setIsCalculatedTopIngredients(true);
      setRecommendedIngredients(await api.getRecommendedIngredients(userScores));
      if (step2InnerCount >= 5) {
        // getRecommendedIngredients(3);
        setOpenedQuestions((prev) => ({
          ...prev,
          [isOnStep + 1]: {
            ...prev[isOnStep + 1],
            [step3InnerCount]: true,
          },
        }));
        nextStep();
        setStep3InnerCount(1);
        setIsOnMultiSelectResponses(false);
        setIsOnImgMultiSelectResponses(true);
      } else {
        // if (step2InnerCount === 1) getRecommendedIngredients(1);
        // if (step2InnerCount === 2) getRecommendedIngredients(2);
        // if (step2InnerCount > 2) getRecommendedIngredients(3);
        setOpenedQuestions((prev) => ({
          ...prev,
          [isOnStep]: {
            ...prev[isOnStep],
            [step2InnerCount + 1]: true,
          },
        }));
        setStep2InnerCount(step2InnerCount + 1);
      }
    }
    //TODO getRecommendedIngredients  and to find the user score
    if (isOnStep === 3) {
      setRecommendedIngredients( await api.getRecommendedIngredients(userScores)); //TODO
      positionController({
        x:
          screenSize.width > 1200
            ? QUESTION_WIDTH
            : screenSize.width > 999
            ? QUESTION_WIDTH_1200_1000
            : QUESTION_WIDTH_999_768,
        y: 0,
      });
      if (step3InnerCount >= 4) {
        setOpenedQuestions((prev) => ({
          ...prev,
          [isOnStep + 1]: {
            ...prev[isOnStep + 1],
            [step4InnerCount]: true,
          },
        }));
        nextStep();
        setStep4InnerCount(1);
        setIsOnMultiSelectResponses(true);
        setIsOnImgMultiSelectResponses(false);
      } else {
        setOpenedQuestions((prev) => ({
          ...prev,
          [isOnStep]: {
            ...prev[isOnStep],
            [step3InnerCount + 1]: true,
          },
        }));
        setStep3InnerCount(step3InnerCount + 1);
      }
    }
    //TODO getRecommendedIngredients  and to find the user score
    if (isOnStep === 4) {
      setRecommendedIngredients(await api.getRecommendedIngredients(userScores)); //TODO
      setOpenedQuestions((prev) => ({
        ...prev,
        [isOnStep]: {
          ...prev[isOnStep],
          [step4InnerCount + 1]: true,
        },
      }));
      setStep4InnerCount(step4InnerCount + 1);
      positionController({
        x:
          screenSize.width > 1200
            ? QUESTION_WIDTH
            : screenSize.width > 999
            ? QUESTION_WIDTH_1200_1000
            : QUESTION_WIDTH_999_768,
        y: 0,
      });
      openedQuestions[isOnStep][step4InnerCount] = true;
      if (step4InnerCount >= 5) {
        // getSmoothies();
        setContainerPosition({ x: 0, y: 0 });
        displayEmailPage();
        //displaySmoothiePage();
      }
    }
  };

  const previousInnerStep = () => {
    previousPositionController({
      x:
        screenSize.width > 1200
          ? QUESTION_WIDTH
          : screenSize.width > 999
          ? QUESTION_WIDTH_1200_1000
          : QUESTION_WIDTH_999_768,
      y: 0,
    });
    if (isOnStep === 1) {
      if (step1InnerCount < 2) {
        previousStep();
      } else {
        setStep1InnerCount(step1InnerCount - 1);
      }
    }
    if (isOnStep === 2) {
      if (step2InnerCount < 2) {
        previousStep();
      } else {
        setStep2InnerCount(step2InnerCount - 1);
      }
    }
    if (isOnStep === 3) {
      if (step3InnerCount < 2) {
        previousStep();
        setIsOnMultiSelectResponses(true);
        setIsOnImgMultiSelectResponses(false);
      } else {
        setStep3InnerCount(step3InnerCount - 1);
      }
    }
    if (isOnStep === 4) {
      if (step4InnerCount <= 1) {
        previousStep();
        setStep3InnerCount(4);
        setIsOnMultiSelectResponses(false);
        setIsOnImgMultiSelectResponses(true);
        return;
      } else {
        setStep4InnerCount(step4InnerCount - 1);
      }
    }
  };

  function updateUserResponses(
    step,
    question,
    newAnswer,
    isBonus = false,
    bonusQuestionNumber
  ) {
    if (isBonus) {
      console.log(
        responses[step][question].bonus_questions[bonusQuestionNumber]
      );
      setResponses((prevState) => ({
        ...prevState,
        [step]: {
          ...prevState[step],
          [question]: {
            ...prevState[step][question],
            bonus_questions: {
              ...prevState[step][question].bonus_questions,
              [bonusQuestionNumber]: {
                ...prevState[step][question].bonus_questions[
                  bonusQuestionNumber
                ],
                answer: newAnswer,
              },
            },
          },
        },
      }));

      return;
    }

    if (step === "Step4" && question === "Q5") {
      responses[step][question].answer = newAnswer;
      setResponses({
        ...responses,
        [step]: {
          ...responses[step],
          [question]: {
            ...responses[step][question],
            answer: newAnswer,
          },
        },
      });
    } else {
      setResponses((prevState) => ({
        ...prevState,
        [step]: {
          ...prevState[step],
          [question]: {
            ...prevState[step][question],
            answer: newAnswer,
          },
        },
      }));
    }
  }

  function handleSaveUserResponsesMulti(
    step,
    responseText,
    action,
    questionNumber,
    bonusQuestionNumber = null
  ) {
    let newArray = [];
    let preArray = bonusQuestionNumber
      ? responses[step][questionNumber].bonus_questions[bonusQuestionNumber]
          .answer
      : responses[step][questionNumber].answer;

    // if array exists make a copy of it
    if (Array.isArray(preArray)) {
      newArray = [...preArray];
    }

    // add to array if item is checked
    if (action === "push") {
      newArray.push(responseText);
    }
    // remove from array if item is unchecked
    else {
      const index = newArray.indexOf(responseText);
      newArray.splice(index, 1);
    }

    const isBonus = !!bonusQuestionNumber;
    updateUserResponses(
      step,
      questionNumber,
      newArray,
      isBonus,
      bonusQuestionNumber
    );
  }

  /* function will update the user's scores after each response to a question.
    pass an object with category-score pairs to the updateUserScores function. 
    Each property in the updates object represents a category, 
    and its corresponding value represents the updated score for that category */
  function updateUserScores(updates) {
    setUserScores({ ...userScores, ...updates });
  }

 
  
  useEffect(() => {
    // console.log('smoothieIngredients:', smoothieIngredients);
    // console.log('smoothie:', smoothie);
    console.log(qtips,'getAllQtips');
  }, [qtips]);
  useEffect(() => {
    // console.log('smoothieIngredients:', smoothieIngredients);
    // console.log('smoothie:', smoothie);
  }, [smoothie, smoothieIngredients]);

  useEffect(() => {
    // console.log(`--------------------Step1------------------`);
    // console.warn("email: ", responses.email);
    // console.log(`after! change step1, Q1 answer: ${responses.Step1.Q1.answer}`);
    // console.log(`after! change step1, Q2 answer: ${responses.Step1.Q2.answer}`);
    // console.log(`after! change step1, Q3 answer: ${responses.Step1.Q3.answer}`);
    // console.log(`--------------------Step2------------------`);
    // console.log(`after! change step2, Q1 answer: ${responses.Step2.Q1.answer}`);
    // console.log(`after! change step2, Q2 answer: ${responses.Step2.Q2.answer}`);
    // console.log(`after! change step2, Q3 answer: ${responses.Step2.Q3.answer}`);
    console.log(`after! change step2, Q4 answer: ${responses.Step2.Q4.answer}`);
    // console.log(`after! change step2, Q5 answer: ${responses.Step2.Q5.answer}`);
    // console.log(`--------------------Step3------------------`);
    // console.log(`after! change step3, Q1 answer: ${responses.Step3.Q1.answer}`);
    // console.log(`after! change step3, Q2 answer: ${responses.Step3.Q2.answer}`);
    // console.log(`after! change step3, Q3 answer: ${responses.Step3.Q3.answer}`);
    // console.log(`after! change step3, Q4 answer: ${responses.Step3.Q4.answer}`);
    // console.log(`--------------------Step4------------------`);
    // console.log(`after! change step4, Q1 answer: ${responses.Step4.Q1.answer}`);
    // console.log(`after! change step4, Q2 answer: ${responses.Step4.Q2.answer}`);
    // console.log(`after! change step4, Q3 answer: ${responses.Step4.Q3.answer}`);
    // console.log(`after! change step4, Q4 answer: ${responses.Step4.Q4.answer}`);
    // console.log(`after! change step4, Q5 answer: ${responses.Step4.Q5.answer}`);
    // console.log(
    //   `----------- ${responses.Step3.Q1.bonus_questions.BQ1.answer} -----------------`
    // );
    // console.log(
    //   `----------- ${responses.Step3.Q1.bonus_questions.BQ2.answer} -----------------`
    // );
    // console.log(
    //   `----------- ${responses.Step3.Q1.bonus_questions.BQ3.answer} -----------------`
    // );
    // console.log(
    //   `----------- ${responses.Step3.Q2.bonus_questions.BQ1.answer} -----------------`
    // );
    // console.log(
    //   `----------- ${responses.Step3.Q2.bonus_questions.BQ2.answer} -----------------`
    // );
    // console.log(
    //   `----------- ${responses.Step3.Q2.bonus_questions.BQ3.answer} -----------------`
    // );
    // console.log(
    //   `----------- ${responses.Step3.Q3.bonus_questions.BQ1.answer} -----------------`
    // );
  }, [responses]);

  useEffect(() => {
    // console.log(
    //   `recommended ingredients for the user: ${recommendedIngredients}`
    // );
    console.log(`userScores Seborrhea: ${userScores.Seborrhea}`);
    console.log(`userScores Photoaging: ${userScores.Photoaging}`);
    console.log(`userScores Aging: ${userScores.Aging}`);
    console.log(`userScores Oil: ${userScores['Oily Skin']}`);
    console.log(`userScores Dry: ${userScores['Dry Skin']}`);
    console.log(`userScores Sensitive Skin: ${userScores['Sensitive Skin']}`);
    console.log(`userScores Psoriasis: ${userScores['Psoriasis']}`);
    console.log(
      `userScores Atopic Dermatitis/ Eczema: ${userScores['Atopic Dermatitis/ Eczema']}`
    );
    console.log(`userScores Pigmentation: ${userScores.Pigmentation}`);
    console.log(`userScores Rosacea: ${userScores.Rosacea}`);
    console.log(`userScores Acne: ${userScores.Acne}`);
  }, [recommendedIngredients, userScores]);

  useEffect(() => {
    // console.warn('x: ' + containerPosition.x + ' y: ' + containerPosition.y);
  }, [containerPosition]);

  useEffect(() => {}, [openedQuestions]);

  useEffect(() => {
    console.log(bestMatchSentences);
  }, [bestMatchSentences]);

  const moveStepByCounterClick = async (step, innerStep) => {
    if (openedQuestions[1][3]) {
      setRecommendedIngredients( await api.getRecommendedIngredients(userScores));
    }

    if (openedQuestions[step][innerStep]) {
      let prevStepsCounter = innerStep - 1;
      for (let i = 1; i < step; i++) {
        prevStepsCounter += stepsLength[i];
      }

      setIsOnStep(step);
      setContainerPosition({
        x: -(
          (screenSize.width > 1200
            ? QUESTION_WIDTH
            : screenSize.width > 999
            ? QUESTION_WIDTH_1200_1000
            : QUESTION_WIDTH_999_768) * prevStepsCounter
        ),
        y: 0,
      });
      if (step === 1) {
        setStep1InnerCount(innerStep);
        setIsOnImgMultiSelectResponses(false);
      }
      if (step === 2) {
        setStep2InnerCount(innerStep);
        setIsOnImgMultiSelectResponses(false);
      }
      if (step === 3) {
        setStep3InnerCount(innerStep);
        setIsOnImgMultiSelectResponses(true);
      }
      if (step === 4) {
        setStep4InnerCount(innerStep);
        setIsOnImgMultiSelectResponses(false);
      }
    }
  };

  useEffect(() => {}, [currentX]);

  useEffect(() => {
    console.warn(email);
  }, [email]);

  useEffect(() => {
    console.log('BONUSSSS: ', bonusCounter)
  }, [bonusCounter])

  const getEmail = (e) => {
    setEmail(e.target.value);

    setResponses({
      ...responses,
      email: e.target.value,
    });
  };

  return (
    <div className="App">
      <Introduction
        isOnStep={isOnStep} //Step 0
        isButtonActive={isButtonActive}
        nextStep={nextStep}
        setStep1InnerCount={setStep1InnerCount}
        closeTheQuiz={closeTheQuiz}
      />

      <Header
        isTopIngrediantsOn={isTopIngrediantsOn}
        closeTheQuiz={closeTheQuiz}
        isOnStep={isOnStep}
        name={name}
        isCalculatedTopIngredients={isCalculatedTopIngredients}
        recommendedIngredients={recommendedIngredients}
        getRecommendedIngredients={api.getRecommendedIngredients}
      />
      <Main
        questionWidth={`${
          screenSize.width > 1200
            ? QUESTION_WIDTH
            : screenSize.width > 999
            ? QUESTION_WIDTH_1200_1000
            : QUESTION_WIDTH_999_768
        }px`}
        openedQuestions={openedQuestions}
        questionsAmount={17}
        isTopIngrediantsOn={isTopIngrediantsOn}
        moveStep={moveStepByCounterClick}
        AllQtipsFunc={qtips}
        nextStep={nextStep}
        previousStep={previousStep}
        previousInnerStep={previousInnerStep}
        isOnStep={isOnStep}
        intermediateResult={intermediateResult}
        closeIntermediateResult={closeIntermediateResult}
        containerStyle={containerStyle}
        containerStyleBonus={containerStyleBonus}
        containerStyleBonusStep={containerStyleBonusStep}
        containerStyleY={containerStyleY}
        nextInnerStep={nextInnerStep}
        step1InnerCount={step1InnerCount}
        step2InnerCount={step2InnerCount}
        step3InnerCount={step3InnerCount}
        step4InnerCount={step4InnerCount}
        isButtonActive={isButtonActive}
        isOnMultiSelectResponses={isOnMultiSelectResponses}
        isOnImgMultiSelectResponses={isOnImgMultiSelectResponses}
        userScores={userScores}
        updateUserScores={updateUserScores}
        responses={responses}
        updateUserResponses={updateUserResponses}
        recommendedIngredients={recommendedIngredients}
        name={name}
        isCalculatedTopIngredients={isCalculatedTopIngredients}
        displayPigmentationQuestions={displayPigmentationQuestions}
        displayAcneQuestions={displayAcneQuestions}
        displayRednessQuestions={displayRednessQuestions}
        activatePigmentationQuestions={activatePigmentationQuestions}
        activateAcneQuestions={activateAcneQuestions}
        activateRednessQuestions={activateRednessQuestions}
        nextBonusQuestion={nextBonusQuestion}
        returnFromBonusQuestion={returnFromBonusQuestion}
        isNameEmpty={isNameEmpty}
        previousBonusQuestion={previousBonusQuestion}
        getUserName={(e) => {
          setName(e.target.value);
          updateUserResponses("Step1", "Q1", e.target.value);
          if (name.length > 0) {
            setIsNameEmpty(false);
          }
        }}
        handleSaveUserResponsesMulti={handleSaveUserResponsesMulti}
        getRecommendedIngredients={api.getRecommendedIngredients}
        bonusCounter={bonusCounter}
        setBonusCounter={setBonusCounter}
      />
      {/* <SmoothieStep
        isOnStep={isOnStep}
        isButtonActive={isButtonActive}
        onClick={displayPersonalRecommendation}
        isResultReady={isResultReady}
        smoothieIngredients={smoothieIngredients}
        smoothie={smoothie}
      /> */}
      <EmailStep
        isOnStep={isOnStep}
        onClick={displayPersonalRecommendation}
        isResultReady={isResultReady}
        getEmail={getEmail}
      />
      <PersonalRecommendation
        isOnStep={isOnStep}
        isButtonActive={isButtonActive}
        recommendedIngredients={recommendedIngredients}
        recommendedProducts={recommendedProducts}
        personalSentences={personalSentences}
        bestMatchSentences={bestMatchSentences}
        smoothieIngredients={smoothieIngredients}
        smoothie={smoothie}
      />
    </div>
  );
}

export default App;